import React, { useState, useEffect, useRef, useCallback } from "react";
import PopupForm from "../../components/popup-form/PopupFormV2";
import baseapi from "../../api/baseapi";
import utils from "../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import { loading, closeLoading } from "../../utils/common";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../components/async-text-box/AsyncTextBox";

export default function SettingForm(props){
    const tempForm = useRef({});
	const formID = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);
	const [startValidation, setStartValidation] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [formValue, setFormValue] = useState({});

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);


	function valueOnChange(e, receivedDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {
			if (e.value !== undefined) {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const copied = { ...tempForm.current };
				copied[dataField] = e.value;
				tempForm.current = copied;
				setFormValue(tempForm.current);

			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit({ stay }) {

		loading(`Saving ${props.title}...`);
		const submittedForm = formValue;
		console.log(submittedForm);
		baseapi
			.httppost(
				utils.extendUrlVar(`${props.apiURL}/save`, {
					id: formID.current || "",
					del: false,
				}),
				submittedForm
			)
			.then((response) => {
				const { data } = response;

				console.log("resposne",data)
		
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Warning",
					action: data.action
				});
			});
	}
	function onInitialized(e) {
		if (formID.current === "new") loading(`Loading New ${props.title}...`);
		else loading(`Loading Existing ${props.title}...`);

		baseapi.httpget(`${props.apiURL}/Get?id=` + (formID.current || "new")).then((response) => {
			const { data } = response;
            const item = data.items;
            const model = data.model;
			
            if(!utils.isNullOrEmpty(item) && !utils.isNullOrEmpty(item[0])){
                tempForm.current = item[0];
            }
            else if(!utils.isNullOrEmpty(model)){
                tempForm.current = model;
            }

            setFormValue(tempForm.current);
			closeLoading();
			formRead.current = true;
		});
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	const customizedFormRender = () => {
		if(props.defaultSettingTemplate === false){
			
		}
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"50%"}
				height={"46%"}
				headerClassName={"maintainance-module-form-title-bg popup-form-title"}
				creditChecking={false}
				apiURL={props.apiURL}
				title={props.title}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized() }}
				onPasteForm={(e) => setFormValue(e.Parent)}
				//copyStorage={"APDebitNoteCopiedData"}
				formData={formValue}
				copyStorage={"CreditTermsCopiedData"}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				onSaving={handleSubmit}
				actionButtonEnabled = {false}
                saveButtonOnly={true}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Active"]}
							elementAttr={{ dataField: "Active" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"25px"}
							height={"50px"}
							iconSize={"18px"}
							disabled={props.lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text' style={{ color: '#808080' }}>Active</span>

					</span>
				}>	<hr className='maintenance-popup-form-hr' />
				<div className='popup-form-main-content'>
                    {
                        utils.isNullOrEmpty(props.customizedFormContent) && <div>
                            <div className='maintenance-module-border-bottom custom-form-grid-section2'>
                                <div className=''>

                                    <div className='popup-group-form-item'>
                                        <div className='popup-group-form-label'>Code : </div>

                                        <div className='popup-group-form-input'>

                                            <AsyncTextBox
                                                value={formValue["Code"]}
                                                elementAttr={{ dataField: "Code" }}
                                                onValueChanged={(e) => {
                                                    valueOnChange(e);
                                                }}
                                                alignment='left'
                                                width={"auto"}
                                                disabled={formID.current !== "new" || props.lockedForm === true}
                                                startValidation={startValidation !== 0}
                                                asyncURL={`${props.apiURL}/CheckDuplicateCode`}
                                                requiredMessage={"Code is required!"}
                                                asyncMessage={"This Code is already taken!"}
                                                asyncProperty={"code"}
                                                asyncArgs={{ id: formID.current }}
                                            />
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className='popup-group-form-item'>
                                <div className='popup-group-form-label'>Description : </div>

                                <div className='popup-group-form-input'>
                                    <TextBox
                                        value={formValue["Name"]}
                                        elementAttr={{ dataField: "Name" }}
                                        onValueChanged={(e) => {
                                            valueOnChange(e);
                                        }}
                                        alignment='left'
                                        width={"auto"}
                                        disabled={props.lockedForm === true}>
                                        <Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
                                    </TextBox>
                                </div>
                            </div>

                            <div className='maintenance-module-border-bottom custom-form-grid-section2'>
                                <div className='popup-group-form-item'>
                                    <div className='popup-group-form-label'>Set As Default : </div>

                                    <div className='popup-group-form-input'>
                                        <CheckBox
                                            value={formValue["IsDefault"]}
                                            elementAttr={{ dataField: "IsDefault" }}
                                            onValueChanged={(e) => {
                                                valueOnChange(e);
                                            }}
                                            alignment='left'
                                            width={"auto"}
                                            disabled={props.lockedForm === true}
                                        >
                                        </CheckBox>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
					
                    {
                        customizedFormRender()
                    }
				</div>
			</PopupForm>
		</div>
	);
}
import React, { useEffect, useState, useRef, useCallback } from "react";
import baseapi from "../../../api/baseapi";
import { TextBox } from "devextreme-react/text-box";
import utils from "../../../utils/common";
import { uomOptions, workshopJobSheet, workshopMechanic, workshopServiceAdvisor, wkInvoiceReportNames, customerOptions, workshopModel } from "../../../utils/lookupstore";
import { Column, Lookup, Summary, TotalItem } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import TextArea from "devextreme-react/text-area";
import { loading, closeLoading } from "../../../utils/common";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import MultipleSelectLookup from "../../../components/lookup/MultipleSelectLookup";
import { getFromLS } from "../../../utils/localstorage";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../../utils/module-calculation";
import { Validator, RequiredRule as ValidatorRequired, CustomRule } from "devextreme-react/validator";
import ServiceAdvisorForm from "../serviceadvisor/form";
import MechanicForm from "../mechanic/form";
import DropDownBox from "devextreme-react/drop-down-box";
import List from 'devextreme-react/list';
import DataGrid from 'devextreme-react/data-grid';
export default function WorkshopInvoiceForm(props) {
	const formID = useRef(null);
	const addToInvoiceActivated = useRef(false);
	const formRef = useRef(null);
	const customerLookupRef = useRef(null);
	const regNoLookupRef = useRef(null);
	const jobNoLookupRef = useRef(null);
	const modelLookupRef = useRef(null);
	const serviceAdvisorLookupRef = useRef(null);
	const mechanicLookupRef = useRef(null);
	const paymentMethodLookupRef = useRef(null);

	//Look up list
	const [customerList, setCustomerList] = useState([]);
	const [serviceAdvisorList, setServiceAdvisorList] = useState([]);
	const [mechanicList, setMechanicList] = useState([]);
	const [jobList, setjobList] = useState([]);
	const [registrationList, setRegistrationList] = useState([]);
	const [paymentMethodList, setPaymentMethodList] = useState([]);
	const [stockList, setStockList] = useState([]);
	const [uomList, setUomList] = useState([]);
	const [chartAccountList, setChartAccountList] = useState([]);
	const [taxCodeList, setTaxCodeList] = useState([]);
	const [modelList, setModelList] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [creditTermList, setCreditTermList] = useState([]);
	const GridRequiredColumns = ["StockID"];
	const formRead = useRef(false);
	const tempForm = useRef(null);
	const defaultAddress = useRef(null);
	const childrenGridRef = useRef(null);
	const [formValue, setFormValue] = useState({});
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const childrenValidatedColumns = ["StockID"];
	const regNoRef = useRef({});
	const defaultChildrenDetails = useRef({});
	const [startValidation, setStartValidation] = useState(0);
	const [jobNoDataSource, setJobNoDataSource] = useState([]);
	const [confirmReadJobDetails, setConfirmReadJobDetails] = useState(false);
	const [disablePayment, setDisablePayment] = useState(false);
	const projectLookupRef = useRef(null);
	const [projectMainList, setProjectMainList] = useState([]);
	const [lockedForm, setLockedForm] = useState(false);
	const [locationList, setLocationList] = useState([]);
	const [defaultLocation, setDefaultLocation] = useState({ id: '', code: '' });
	const serviceValues = [1000, 5000, 10000];
	const nextServiceDropDownBoxRef = useRef(null);
	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (!utils.isNullOrEmpty(props.formID)) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}

		if (utils.isEmptyArray(uomList)) {
			uomOptions.getAll().then((list) => {
				setUomList(list.data);
			});
		}
	}, [props.formID]);




	function valueOnChange(e, receivedDataField = null) {
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);
			// console.log("On Value Change", e.value, "data field", dataField)

			if (dataField === "CustomerID" && formRead.current) {
				// Fetch customer details from the API
				baseapi.httpget(`api/Customer/Get`, { id: e.value }).then((response) => {
					const { data } = response;
					const copied = { ...tempForm.current };
					const customerFound = data.model;
					const address1 = customerFound.Address1 ?? "";
					const address2 = customerFound.Address2 ?? "";
					const address3 = customerFound.Address3 ?? "";

					// Update the copied object with new data
					if (defaultAddress.current === null) {
						copied["Address"] = address1 + "\n" + address2 + "\n" + address3;
					} else {
						defaultAddress.current = null;
					}

					copied["CustomerName"] = customerFound?.Name;
					copied["Address1"] = address1;
					copied["Address2"] = address2;
					copied["Address3"] = address3;
					copied["Telephone"] = customerFound?.Telephone;
					copied["Terms"] = customerFound?.Term;
					copied["CreditTermsID"] = customerFound?.CreditTerm;
					copied["CreditTermsCode"] = customerFound?.CreditTermCode;
					copied["SalesmanID"] = customerFound?.SalesmanID;

					// Handle payment method based on forexCode
					if (customerFound.forexCode !== "RM") {
						setDisablePayment(true);
						copied["DeductAmount"] = 0;
						copied["PaymentMethod"] = null;
					} else {
						setDisablePayment(false);
					}

					// Additional logic for "Add By Job Sheet" input type
					if (props.inputType === "Add By Job Sheet") {
						copied["RegID"] = null;
						utils.childrenGridSetSource(childrenGridRef, []);
					}

					// Update state with the new data from the API response
					tempForm.current = copied;
					setFormValue(tempForm.current);
				}).catch(err => {
					console.error("Error fetching customer data:", err);
				});
			} else if (dataField === "DeductAmount" && formRead.current) {
				const copied = { ...tempForm.current };
				const copiedDetails = utils.childrenGridGetSource(childrenGridRef);
				var balance = 0;

				for (var i = 0; i < Object.keys(copiedDetails).length; i++) {

					balance += parseFloat(copiedDetails[i]["NettAmount"]);
				}
				copied["OutstandingAmount"] = balance - e.value;



				setFormValue(copied);
			} else if (dataField === "RegID" && formRead.current) {
				const regNoFound = registrationList.find((c) => c.id === e.value);
				copied["Address"] = '';
				copied["Telephone"] = '';
				copied["CreditTermsID"] = '';
				copied["CreditTermsCode"] = '';

				if (regNoFound !== undefined) {
					const address1 = regNoFound.address1 ?? "";
					const address2 = regNoFound.address2 ?? "";
					const address3 = regNoFound.address3 ?? "";
					if (copied["Address"] === null || copied["Address"] === undefined || copied["Address"].trim().length === 0) {
						copied["Address"] = address1 + "\n" + address2 + "\n" + address3;
					}
					copied["CustomerName"] = regNoFound?.customerName;
					copied["CustomerCode"] = regNoFound?.customerCode;
					copied["Address1"] = address1;
					copied["Address2"] = address2;
					copied["Address3"] = address3;
					copied["Telephone"] = regNoFound?.telephone;
					copied["CreditTermsID"] = regNoFound?.creditTermID;
					copied["CreditTermsCode"] = regNoFound?.creditTermCode;
					copied["SalesmanID"] = regNoFound?.salesmanID;
					copied["CustomerID"] = regNoFound?.customerID;
					copied["ModelCode"] = regNoFound?.modelCode;
					copied["ModelID"] = regNoFound?.modelID;
					tempForm.current = copied;
					setFormValue(tempForm.current);
				}
			} else if (dataField === "Address" && formRead.current) {
				const lines = e.value.split(/\r?\n|\\n/);
				copied["Address"] = utils.toUpperCase(e.value);
				copied["Address1"] = utils.toUpperCase(lines[0]);
				copied["Address2"] = utils.toUpperCase(lines[1]);
				copied["Address3"] = utils.toUpperCase(lines[2]);

				tempForm.current = copied;
				setFormValue(tempForm.current);
			} else if (dataField === "DocumentDate") {
				// Add 6 months to the DocumentDate
				const documentDate = new Date(e.value);
				const nextServiceDate = new Date(documentDate.setMonth(documentDate.getMonth() + 6));
				copied["NextServiceDate"] = nextServiceDate;

				// Update the state with the new NextServiceDate
				tempForm.current = copied;
				setFormValue(tempForm.current);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit(e) {
		//console.log(dropDownList, e.validationGroup.validate(), form);

		if (!utils.isNullOrEmpty(e.validData)) {
			const validData = e.validData;
			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]

			};
			loading(`Saving Invoice...`);
			baseapi.httppost(utils.extendUrlVar("/api/WKInvoice/save", { id: formID.current || "", del: false, }), submittedForm).then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
			});

			if (props.clearTransfer) {
				props.clearTransfer(false);
			}
		}
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
		if (props.clearTransfer) {
			props.clearTransfer(false);
		}
	};

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Invoice...");
		else loading("Loading Existing Invoice...");


		baseapi.httpget("/api/WKInvoice/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);


			if (data.isTransfer) {
				setLockedForm(true)
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: "This invoice is being transferred to job sheet, Cannot be edited",
					type: "Warning",
				});
			}

			if (formID.current === "new") {
				tempForm.current = data.model.Parent;

				if (props.transfer) {
					const jobData = getFromLS("WorkshopJobSheetAddToInvoice");

					console.log("jobData", jobData)
					if (jobData !== null) {
						tempForm.current["CustomerID"] = jobData["Parent"]["CustomerID"];
						tempForm.current["CustomerName"] = jobData["Parent"]["CustomerName"];
						tempForm.current["CustomerCode"] = jobData["Parent"]["CustomerCode"];
						tempForm.current["Address"] = jobData["Parent"]["Address"];
						tempForm.current["Address1"] = jobData["Parent"]["Address1"];
						tempForm.current["Address2"] = jobData["Parent"]["Address2"];
						tempForm.current["Address3"] = jobData["Parent"]["Address3"];
						tempForm.current["Telephone"] = jobData["Parent"]["Telephone"];
						tempForm.current["Terms"] = jobData["Parent"]["Terms"];
						tempForm.current["CreditTermsID"] = jobData["Parent"]["CreditTermsID"];
						tempForm.current["CreditTermsCode"] = jobData["Parent"]["CreditTermsCode"];
						tempForm.current["SalesmanID"] = jobData["Parent"]["SalesmanID"];
						tempForm.current["RegID"] = jobData["Parent"]["RegisterID"];
						tempForm.current["RegCode"] = jobData["Parent"]["RegisterCode"];
						tempForm.current["ModelID"] = jobData["Parent"]["ModelID"];
						tempForm.current["Mileage"] = jobData["Parent"]["Mileage"];
						tempForm.current["ModelCode"] = jobData["Parent"]["ModelCode"];
						tempForm.current["Remarks"] = jobData["Parent"]["Remarks"];
						tempForm.current["ServiceAdvisorID"] = jobData["Parent"]["ServiceAdvisorID"];
						tempForm.current["ServiceAdvisorName"] = jobData["Parent"]["ServiceAdvisorName"];
						tempForm.current["MechanicID"] = jobData["Parent"]["MechanicID"];
						tempForm.current["MechanicName"] = jobData["Parent"]["MechanicName"];

					}

					const updatedChildren = jobData["Children"].map((child, index) => {
						return {
							...child, // Keep other properties
							Line: index + 1 // Set the Line number starting from 1
						};
					});
					//utils.childrenGridSetSource(childrenGridRef, updatedChildren);
					onRowUpdated({ key: null }, updatedChildren);
				} else {
					utils.childrenGridAddRow(childrenGridRef); //Add row for the first grid
				}

				setFormValue(tempForm.current);
			} else {
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
				utils.childrenGridSetSource(childrenGridRef, data.model.Children);


			}

			closeLoading();
			formRead.current = true;

		});
	}

	const onInitNew = () => {
		return Object.assign(utils.CommonCalculationColumns, {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			Quantity: 0,
			UnitPrice: 0,
			ItemDiscountAmt: 0,
			Amount: 0,
			UnitCost: 0,
			NettAmount: 0,
			DetailLN: 0,
			Point: 0,
			PrintPrice: false,
			Confirmation: false,
			Show: false,
			BOM: false,
			ProductionNo: "",
			Details: false,
			GroupUp: 0,
			GroupAmt: 0,
			BaseRate: 0.0,
			PriceDisplay: false,
			DeliveryNo: "",
			DODate: "2023/12/1",
			LocationID: utils.popupFormGetDefaultValues(formRef)["LocationID"],
			LocationCode: utils.popupFormGetDefaultValues(formRef)["LocationCode"],
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
		});
	};

	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = utils.roundUpObjectProperties(moduleCalculation(currentRow, parentValue));
	};

	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		const ParentValue = { ...tempForm.current };
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);
		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, calculationResult.children);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const selectedItem = e.selectedItem;
		const dataSource = e.dataSource;
		currentRecords[rowIndex][columnName] = e.value;

		if (columnName === "StockID") {
			currentRecords[rowIndex]["StockCode"] = selectedItem?.code;
			currentRecords[rowIndex]["Description"] = selectedItem?.name;
			currentRecords[rowIndex]["UnitPrice"] = selectedItem?.price1;
			currentRecords[rowIndex]["ChartAccountID"] = selectedItem?.cashSalesChartAccountID;
			currentRecords[rowIndex]["ChartAccountCode"] = selectedItem?.cashSalesChartAccountCode;
			currentRecords[rowIndex]["UOMID"] = selectedItem?.uomID;
			currentRecords[rowIndex]["UOMCode"] = selectedItem?.uomCode;
			currentRecords[rowIndex]["MechanicID"] = formValue["MechanicID"];
			const copiedArr = [...stockList];
			if (!utils.isInArray(stockList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setStockList(copiedArr);
		} else if (columnName === "TaxCodeID") {
			currentRecords[rowIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			const copiedArr = [...taxCodeList];
			if (!utils.isInArray(taxCodeList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setTaxCodeList(copiedArr);
		} else if (columnName === "MechanicID") {
			const copiedArr = [...mechanicList];
			if (!utils.isInArray(mechanicList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setMechanicList(copiedArr);
		} else if (columnName === "ChartAccountID") {
			const copiedArr = [...chartAccountList];
			if (!utils.isInArray(chartAccountList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setChartAccountList(copiedArr);
		} else if (columnName === "LocationID") {
			currentRecords[rowIndex]["LocationCode"] = selectedItem?.code;
		}

		e["key"] = key;
		e["data"] = currentRecords[rowIndex];
		onRowUpdated(e, currentRecords);
	};
	function validateDeductAmount(e) {
		const copied = { ...tempForm.current };

		if (e.value === null && e.value === undefined) {
			return false;
		}

		if (e.value > copied["MainForexAmount"]) return false;
		else return true;
	}
	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);
	function handleNextServiceSelection(e) {
		// Get the selected service value
		const selectedServiceValue = e.data.value;

		// Add the selected service value to the current Mileage value
		const updatedMileage = (formValue["Mileage"] || 0) + selectedServiceValue;

		// Update the form values
		valueOnChange({ value: updatedMileage }, "NextService");

		if (nextServiceDropDownBoxRef.current) {
			nextServiceDropDownBoxRef.current.instance.close();
		}
	}

	const serviceDataSource = serviceValues.map(value => ({
		value: value,
		displayValue: `+${value.toLocaleString()}`,
	}));
	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={childrenGridRef}
				ReportDocumentNo={formValue["DocumentNo"]}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				lockFormOnChanged={setLockedForm}
				headerClassName={"workshop-module-form-title-bg popup-form-title"}
				creditChecking={false}
				onShown={onInitialized}
				apiURL={props.apiURL}
				disableButtons={lockedForm === true}
				title={props.title}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				copyFields={{
					Parent: formValue,
					Children: utils.childrenGridGetSource(childrenGridRef)
				}}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
					onRowUpdated({ key: null }, e.Children);
				}}

				requiredColumns={{
					Children: ["StockID", "ChartAccountID"]
				}}
				filterRules={{
					Children: [
						{ name: "StockID", rules: [{ name: "Required" }] },
						{ name: "Description", rules: [{ name: "Required" }] },
						{ name: "Quantity", rules: [{ name: "Min", value: 1 }] },
					]
				}}
				copyStorage={"WorkshopInvoiceCopiedData"}
				formData={formValue}
				defaultSelectedReport={"Ykub0Opzjac%3d"}
				reportURL={wkInvoiceReportNames.url}
				onClosing={clearForm}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[customerLookupRef, regNoLookupRef, modelLookupRef, serviceAdvisorLookupRef, mechanicLookupRef, projectLookupRef]}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							disabled={lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div className='popup-from-group-container'>
					<div className='popup-group-form-container workshop-module-border-bottom'>
						<div className='popup-form-item-container3'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Reg No: </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={regNoLookupRef}
										setValue={regNoRef.current}
										className={"workshop-listing-datagrid"}
										dataSource={registrationList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["RegID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "RegID");
										}}
										disabled={props.inputType === "Add By Job Sheet" || lockedForm === true}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Reg No is required!"}
										dataSourceURL={"api/Utilities/GetWKRegistration"}
										onDataSourceChanged={setRegistrationList}
										displayText={formValue["RegCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='modelCode'></Column>
									</CustomizedLookup>
								</div>
							</div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label next-doc-label'>Invoice No:</div>

								<div className='popup-group-form-input'>
									<div className='next-doc-display'>Next Doc : {formValue["NextDocNo"]}</div>
									<AsyncTextBox
										value={formValue["DocumentNo"]}
										elementAttr={{ dataField: "DocumentNo" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										placeholder={"<NEW>"}
										disabled={lockedForm === true || props.formID !== "new"}
										asyncURL={"api/WKInvoice/CheckDuplicateDocNo"}
										asyncMessage={"This Invoice No is already taken!"}
									/>
								</div>
							</div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Date: </div>

								<div className='popup-group-form-input'>
									<DateBox
										value={formValue["DocumentDate"]}
										elementAttr={{ dataField: "DocumentDate" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										displayFormat='dd/MM/yyyy'
										dateSerializationFormat='yyyy-MM-dd'
										useMaskBehavior={true}
										disabled={lockedForm === true}></DateBox>
								</div>
							</div>
						</div>
						<div className='popup-form-item-container6'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Code: </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={customerLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={customerList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["CustomerID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "CustomerID");
										}}
										disabled={lockedForm === true}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Customer Code is required!"}
										dataSourceURL={"api/Utilities/GetCustomers"}
										onDataSourceChanged={setCustomerList}
										displayText={formValue["CustomerCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Customer Name"}></Column>
									</CustomizedLookup>
								</div>
							</div>




							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Name: </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["CustomerName"]}
										elementAttr={{ dataField: "CustomerName" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={lockedForm === true}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Name is required!"} />}</Validator>
									</TextBox>
								</div>
							</div>
						</div>
						<div className='popup-form-item-container5'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label' style={{ paddingBottom: '50px' }}>Address: </div>

								<div className='popup-group-form-input'>
									<TextArea
										value={formValue["Address"]}
										elementAttr={{ dataField: "Address" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										height={120}
										disabled={lockedForm === true}></TextArea>
								</div>
							</div>
							<div className='popup-form-item-container1'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Model: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={modelLookupRef}
											className={"workshop-listing-datagrid"}
											dataSource={modelList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ModelID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ModelID");
											}}
											//acceptCustomValue={true}
											dataSourceURL={"api/Utilities/GetItemModels"}
											onDataSourceChanged={setModelList}
											disabled={lockedForm === true}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Model is required!"}
											displayText={formValue["ModelCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Description"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Telephone: </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["Telephone"]}
											elementAttr={{ dataField: "Telephone" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											disabled={lockedForm === true}></TextBox>
									</div>
								</div>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Terms: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"ar-listing-datagrid"}
											dataSource={creditTermList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["CreditTermsID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "CreditTermsID");
											}}
											displayText={formValue["CreditTermsCode"]}
											onDataSourceChanged={setCreditTermList}
											dataSourceURL={"api/Utilities/GetCreditTerms"}
											readOnly={lockedForm === true}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption='Days'></Column>
										</CustomizedLookup>
									</div>
								</div>
							</div>

						</div>

						<div className='popup-form-item-container3'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Service Advisor: </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={serviceAdvisorLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={serviceAdvisorList}
										displayExpr={"name"}
										valueExpr={"id"}
										value={formValue["ServiceAdvisorID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "ServiceAdvisorID");
										}}
										allowAdd={true}
										popupForm={<ServiceAdvisorForm formID={"new"} closePopup={null} directSaveAndClose={true} onRefresh={() => {
											serviceAdvisorLookupRef.current?.resetPagingIndex();
											workshopServiceAdvisor.store.load().then((list) => {
												setServiceAdvisorList(list.data);
											});
										}} />}
										apiURL={"/api/WKServiceAdvisor"}
										disabled={lockedForm === true}
										dataSourceURL={"api/Utilities/GetWKServiceAdvisor"}
										onDataSourceChanged={setServiceAdvisorList}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Service Advisor is required!"}
										displayText={formValue["ServiceAdvisorName"]}>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Mechanic: </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={mechanicLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={mechanicList}
										displayExpr={"name"}
										valueExpr={"id"}
										value={formValue["MechanicID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "MechanicID");
										}}

										allowAdd={true}
										popupForm={<MechanicForm formID={"new"} closePopup={null} directSaveAndClose={true} onRefresh={() => {
											mechanicLookupRef.current?.resetPagingIndex();
											workshopMechanic.store.load().then((list) => {
												setMechanicList(list.data);
											});
										}} />}
										disabled={lockedForm === true}
										dataSourceURL={"api/Utilities/GetWKMechanic"}
										onDataSourceChanged={setMechanicList}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Mechanic is required!"}
										displayText={formValue["MechanicName"]}>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>


							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Issue By: </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["IssueBy"]}
										elementAttr={{ dataField: "IssueBy" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}></TextBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Mileage: </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["Mileage"]}
										elementAttr={{ dataField: "Mileage" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										format="#,##0"
										disabled={lockedForm === true}></NumberBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Next Service: </div>

								<div className='popup-group-form-input'>

									<DropDownBox
										ref={nextServiceDropDownBoxRef}
										value={formValue["NextService"] ? new Intl.NumberFormat().format(Number(formValue["NextService"])) : ''}
										dataSource={serviceValues}
										valueExpr="this"
										displayExpr="this"
										elementAttr={{ dataField: "NextService" }}
										onValueChanged={(e) => {
											// Strip commas from the input value
											const rawValue = e.value ? e.value.replace(/,/g, '') : '';  // Remove commas for numeric operations

											// Update the form values with the raw value
											valueOnChange({ value: rawValue }, "NextService");
										}}
										contentRender={() => (
											<DataGrid
												dataSource={serviceDataSource}
												hoverStateEnabled={true}
												onRowClick={(e) => handleNextServiceSelection(e)}
												height={200}
											>
												<Column dataField="displayValue" caption="Next Service Value" />
											</DataGrid>
										)}
										width={"auto"}
										placeholder="Enter custom values here..."
										showClearButton={true}
										acceptCustomValue={true}
										disabled={lockedForm === true}
									/>

								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Next Service Date: </div>

								<div className='popup-group-form-input'>
									<DateBox
										value={formValue["NextServiceDate"]}
										elementAttr={{ dataField: "NextServiceDate" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										displayFormat='dd/MM/yyyy'
										dateSerializationFormat='yyyy-MM-dd'
										useMaskBehavior={true}
										disabled={lockedForm === true}></DateBox>
								</div>
							</div>
						</div>
						<div className='popup-form-item-container1'>


							<div className='popup-form-item-container3'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Job No: </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["FromJobDocumentNo"]}
											elementAttr={{ dataField: "FromJobDocumentNo" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={true}>
										</TextBox>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>PO No: </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["PoNo"]}
											elementAttr={{ dataField: "PoNo" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											disabled={lockedForm === true}></TextBox>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Project: </div>

									<div className='popup-group-form-input' id='customized-lookup1'>
										<CustomizedLookup
											ref={projectLookupRef}
											className={"ar-listing-datagrid"}
											dataSource={projectMainList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ProjectID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ProjectID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Project is required!"}
											dataSourceURL={"api/Utilities/GetProjects"}
											onDataSourceChanged={setProjectMainList}
											disabled={lockedForm === true}
											displayText={formValue["ProjectCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Project Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>
								<div className='popup-group-form-item' style={{ display: 'none' }}>
									<div className='popup-group-form-label'>Quotation: </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["Quotation"]}
											elementAttr={{ dataField: "Quotation" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											disabled={true}></TextBox>
									</div>
								</div>
							</div>
							<div className='popup-form-item-container5'>

								<div className='popup-group-form-item group-form-item-align-top'>
									<div className='popup-group-form-label'>Remarks: </div>

									<div className='popup-group-form-input'>
										<TextArea
											value={formValue["Remarks"]}
											elementAttr={{ dataField: "Remarks" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											height={75}
											disabled={lockedForm === true}></TextArea>
									</div>
								</div>

							</div>
						</div>

					</div>

					<div className='popup-group-form-container workshop-module-border-bottom popup-form-datagrid-container'>
						<ChildrenDatagrid
							ref={childrenGridRef}
							name={"Children"}
							defaultColumnValues={onInitNew}
							lookupFields={["StockID", "MechanicID", "ChartAccountID", "TaxCodeID"]}
							allowColumnReordering={true}
							allowColumnResizing={true}
							keyExpr='ID'
							showBorders={false}
							onRowUpdating={onRowUpdating}
							onRowUpdated={onRowUpdated}
							onRowRemoved={onRowUpdated}
							disabled={lockedForm === true}
							gridLookupOpen={setGridLookupOpen}
							storageName={"WorkshopInvoiceChildrenGrid"}
							enabledDescription={false}>
							<Column dataField='Line' dataType='number' editorOptions={{ readOnly: true }} caption={"No"} />
							<Column
								visible={true}
								dataField='StockID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.StockCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										dataSourceURL={"/api/Utilities/GetStock"}
										opened={gridLookupOpen}
										className={"workshop-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "StockID")}

									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column dataField='Description' />

							<Column
								visible={true}
								dataField='MechanicID'
								caption={"Mechanic"}
								cellRender={(e) => {
									const data = e.data;
									var result = mechanicList.find((c) => c.id === data.MechanicID)?.name;
									result = data.MechanicID === null ? null : result;
									return result === undefined ? data.MechanicName : result;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										displayExpr={"name"}
										opened={gridLookupOpen}
										className={"workshop-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "MechanicID")}
										dataSourceURL={"api/Utilities/GetWKMechanic"}
										onDataSourceChanged={setMechanicList}
										sourceList={mechanicList}>
										<Column dataField='code'></Column>
										<Column dataField='name' width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='Quantity' dataType='number' caption={"Qty"} />
							<Column
								visible={true}
								dataField='LocationID'
								caption="Location"
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.LocationCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"inventory-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "LocationID")}
										dataSourceURL={"api/Utilities/GetLocations"}
										onDataSourceChanged={setLocationList}

										sourceList={locationList}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column
								visible={true}
								dataField='UOMID'
								cellRender={(e) => {
									const data = e.data;
									return data.UOMCode;
								}}
								editorOptions={{ readOnly: true }}
							/>


							<Column visible={true} dataField='UnitPrice' dataType='number' caption={"U/Price"} format='#,##0.00' />

							<Column visible={true} dataField='ItemDiscount' dataType='number' caption='Discount' format='#,##0.00' />

							<Column visible={true} dataField='ForexAmount' dataType={"number"} />

							<Column
								visible={true}
								dataField='TaxCodeID'
								caption='Tax Code'
								cellRender={(e) => {
									const data = e.data;
									var result = taxCodeList.find((c) => c.id === data.TaxCodeID)?.code;
									result = data.TaxCodeID === null ? null : result;
									return result === undefined ? data.TaxCode : result;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"workshop-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
										dataSourceURL={"api/Utilities/GetGSTRate"}
										onDataSourceChanged={setTaxCodeList}
										sourceList={taxCodeList}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='TaxRate' width={"auto"} caption='Tax Rate' dataType='number' editorOptions={{ disabled: true }} />

							<Column visible={true} dataField='TaxInclusive' caption='Tax Inclusive' dataType='boolean' value={false} />

							<Column visible={true} dataField='ForexTaxable' caption='Tax FX Taxable' dataType='number' editorOptions={{ disabled: true }} format='#,##0.00' />

							<Column visible={true} dataField='ForexTaxAmount' caption='Tax Amount' dataType='number' editorOptions={{ disabled: true }} format='#,##0.00' />

							<Column visible={true} dataField='NettAmount' dataType='number' editorOptions={{ disabled: true }} caption={"Sub Total"} format='#,##0.00' />


							<Column
								visible={true}
								dataField='ChartAccountID'
								caption={"Chart Acc."}
								cellRender={(e) => {
									const data = e.data;
									var result = chartAccountList.find((c) => c.id === data.ChartAccountID)?.code;
									result = data.ChartAccountID === null ? null : result;
									return result === undefined ? data.ChartAccountCode : result;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"workshop-listing-datagrid"}
										displayExpr={"code"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ChartAccountID")}
										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										onDataSourceChanged={setChartAccountList}
										sourceList={chartAccountList}>
										<Column dataField='code'></Column>
										<Column dataField='name' width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>
							<Column visible={true} dataField='ImportDocumentNo' caption='From Document No' editorOptions={{ disabled: true }} width={150} />
							<Column visible={false} dataField='ItemDiscountAmt' dataType='number' />

							<Column visible={false} dataField='UnitCost' format='#,##0.00' />

							<Summary recalculateWhileEditing={true}>
								<TotalItem column='Quantity' summaryType='sum' customizeText={calculateDataGridTotal} />

								<TotalItem column='ItemDiscountAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='UnitCost' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							</Summary>
						</ChildrenDatagrid>
					</div>

					<div className='workshop-module-border-bottom custom-form-grid-section2'>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Payment Method: </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={paymentMethodLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={paymentMethodList}
										displayExpr={"name"}
										valueExpr={"id"}
										value={formValue["PaymentMethodID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "PaymentMethodID");
										}}
										disabled={(lockedForm || disablePayment) === true}
										onDataSourceChanged={setPaymentMethodList}
										dataSourceURL={"api/Utilities/GetCashSalesPaymentMethod"}
										startValidation={startValidation !== 0}

										displayText={formValue["PaymentMethodName"]}>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Amount: </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["DeductAmount"]}
										elementAttr={{ dataField: "DeductAmount" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										format='#,##0.00'
										disabled={(lockedForm || disablePayment) === true}>
										<Validator>
											{startValidation !== 0 && <ValidatorRequired message={"Amount is required!"} />}
											{startValidation !== 0 && <CustomRule validationCallback={(e) => validateDeductAmount(e)} message={`Amount Must Not Be More Than ${formValue["MainForexAmount"]}`} />}
										</Validator>
									</NumberBox>
								</div>
							</div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Bank Charge: </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["BankCharges"]}
										elementAttr={{ dataField: "BankCharges" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										format='#,##0.00'
										disabled={(lockedForm || disablePayment) === true}>
									</NumberBox>
								</div>
							</div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Cheque No: </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["ChequeNo"]}
										elementAttr={{ dataField: "ChequeNo" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										format='#,##0.00'
										disabled={(lockedForm || disablePayment) === true}>

									</TextBox>
								</div>
							</div>
						</div>

						<div className='popup-form-item-container2 custom-form-item-container2'>
							<div></div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Total: </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["MainForexAmount"]}
										elementAttr={{ dataField: "MainForexAmount" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={true}
										format='#,##0.00'>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Total is required!"} />}</Validator>
									</NumberBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label' style={{ width: "80px" }}>
									Rounding:
								</div>

								<div className='popup-group-form-input' style={{ paddingBottom: "7px" }}>
									<CheckBox
										value={formValue["Rounding"]}
										elementAttr={{ dataField: "Rounding" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={lockedForm === true}></CheckBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Outstanding: </div>

								<div className='popup-group-form-input'>
									<NumberBox
										value={formValue["OutstandingAmount"]}
										elementAttr={{ dataField: "OutstandingAmount" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={true}
										format='#,##0.00'>

									</NumberBox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PopupForm>
		</div>
	);
}

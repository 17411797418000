import React, { useCallback, useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import utils from "../../utils/common";
import { loading, closeLoading } from "../../utils/common";
import './UpcomingForm.scss';
import TermsOfUse from '../popup-form/TermsOfUse';
import PrivacyPolicy from '../popup-form/PrivacyPolicy';

const UpcomingPlanDetailForm = ({ visible, onClose }) => {

    const [popupWidth, setPopupWidth] = useState("65%");
    const [upcomingPaymentDetails, setUpcomingPaymentDetails] = useState(null); // State for API data

    useEffect(() => {
        const updatePopupWidth = () => {
            if (window.innerWidth < 768) {
                setPopupWidth("95%");
            } else {
                setPopupWidth("65%");
            }
        };

        updatePopupWidth();
        window.addEventListener('resize', updatePopupWidth);

        return () => {
            window.removeEventListener('resize', updatePopupWidth);
        };
    }, []);

    useEffect(() => {
        if (visible) {
            loading("Loading Subscription Plan...");
            const fetchPaymentDetails = async () => {
                try {
                    const endpoint = '/api/Payment/GetUpcomingPlanDetail';

                    baseapi.httpget(endpoint)
                        .then(response => {
                            if (response.data.status) {
                                // Update the state with the response data
                                setUpcomingPaymentDetails(response.data.UpcomingPlanDetails);
                            } else {
                                notify(response.data.message, 'error', 2000);
                            }
                        })
                        .catch(error => {
                            console.error('Error processing payment details:', error.message);
                        })
                        .finally(() => closeLoading());
                } catch (error) {
                    console.error('Error fetching payment details:', error.message);
                    closeLoading();
                }
            };

            fetchPaymentDetails();
        }
    }, [visible]);

    const handleOnClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    return (
        <>
            <Popup
                visible={visible}
                onHiding={onClose}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                width={popupWidth}
                height={"auto"}
                title='Review Payment Details'
            >
                {upcomingPaymentDetails ? (
                    <div className="review-payment-container">
                        <div className="reviewpayment-section">
                            <div className="reviewpayment-details">
                                <div className="reviewpayment-plan-box">
                                    <h4>{upcomingPaymentDetails.PlanName}</h4>

                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Duration</div>
                                        <div className="summary-value">{upcomingPaymentDetails.DisplayDuration}</div>
                                    </div>

                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Subscription</div>
                                        <div className="summary-value">
                                            {new Date(upcomingPaymentDetails.SubscriptionStartDate).toLocaleDateString('en-GB')} - {new Date(upcomingPaymentDetails.SubscriptionEndDate).toLocaleDateString('en-GB')}
                                        </div>
                                    </div>

                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Included User(s)</div>
                                        <div className="summary-value">{upcomingPaymentDetails.IncludedLicensedUser}</div>
                                    </div>

                                    <div className="reviewpayment-item">
                                        <div className="summary-label">Fee:</div>
                                        <div className="summary-value">
                                            RM {utils.numberToCurrency(upcomingPaymentDetails.PlanPriceTotal)}<br />
                                            <span className="reviewpayment-additionaltext">RM {utils.numberToCurrency(upcomingPaymentDetails.PlanAmount)} x {upcomingPaymentDetails.Duration} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="reviewpayment-summary">
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Additional User(s)</div>
                                    <div className="summary-value">{upcomingPaymentDetails.AddOnUser}</div>
                                </div>
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Additional Charge for User(s)</div>
                                    <div className="summary-value">
                                        RM {utils.numberToCurrency(upcomingPaymentDetails.LicensedPriceTotal)}<br />
                                        <span className="reviewpayment-additionaltext">{upcomingPaymentDetails.AddOnUser} seat x {upcomingPaymentDetails.Duration} x RM {utils.numberToCurrency(upcomingPaymentDetails.LicensedUserPrice)}</span>
                                    </div>
                                </div>
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">SST (8%)</div>
                                    <div className="summary-value">
                                        RM {utils.numberToCurrency(upcomingPaymentDetails.SSTPrice)}
                                    </div>
                                </div>
                                <div className="reviewpayment-item">
                                    <div className="summary-review-label">Total Fee</div>
                                    <div className="summary-value reviewpayment-total">
                                        <strong>RM {utils.numberToCurrency(upcomingPaymentDetails.TotalPrice)}</strong>
                                        {upcomingPaymentDetails.DiscountPrice > 0 && (
                                            <>
                                                <br />
                                                <span className="reviewpayment-additionaltext">
                                                    Save RM {utils.numberToCurrency(upcomingPaymentDetails.DiscountPrice)}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="reviewpayment-buttons">
                            <button className="reviewpayment-submit" onClick={handleOnClose}>Cancel</button>
                        </div>
                    </div>
                ) : (
                    <p>Loading payment details...</p>
                )}
            </Popup>


        </>
    );
};

export default UpcomingPlanDetailForm;

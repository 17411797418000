import React, { useCallback, useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import utils from "../../utils/common";
import { loading, closeLoading } from "../../utils/common";
import "./PlanSelectionForm.scss";
const PlanSelectionForm = ({ visible, onClose, activeTab, onUpdate }) => {
    const [selectedPlanID, setSelectedPlanID] = useState(null);
    const [companyPlan, setCompanyPlan] = useState(null);
    const [userCount, setUserCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [outstandingAmount, setOutstandingAmount] = useState(0);
    const [outstandingLicensedUser, setOutstandingLicensedUser] = useState(0);
    const [outstandingPlanID, setOutstandingPlanID] = useState(0);
    const [userLicensePrice, setUserLicensePrice] = useState(0);
    const [userLicensePriceMonth, setUserLicensePriceMonth] = useState(0);
    const [planPriceDuration, setPlanPriceDuration] = useState(0);
    const [initialPlanID, setInitialPlanID] = useState(null);
    const [initialUserCount, setInitialUserCount] = useState(0);
    const [initialDuration, setInitialDuration] = useState(null);
    const [popupWidth, setPopupWidth] = useState("55%");
    const [planPrices, setPlanPrices] = useState({});
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [dropDownList, setDropDownList] = useState({
        planDurationList: [], promotionList: []
    });
    const [plans, setPlans] = useState([]);
    const [chargeableUsers, setChargeableUsers] = useState(0);
    const [outstandingUserLicensePrice, setOutstandingUserLicensePrice] = useState(0);
    const [outstandingPlanPriceDuration, setOutstandingPlanPriceDuration] = useState(0);
    const [outstandingMonths, setOutstandingMonths] = useState(0);
    const [planFee, setPlanFee] = useState(0);
    const [planOutstandingFee, setPlanOutstandingFee] = useState(0);
    const [planIntialFee, setInitialPlanFee] = useState(0);
    const isUpgrade = activeTab === 'upgrade';


    useEffect(() => {
        const updatePopupWidth = () => {
            if (window.innerWidth < 768) {
                setPopupWidth("95%");
            } else if (window.innerWidth >= 768 && window.innerWidth < 1700) {
                setPopupWidth("65%");
            } else if (window.innerWidth >= 1700) {
                setPopupWidth("55%");
            }
        };

        updatePopupWidth();
        window.addEventListener('resize', updatePopupWidth);

        return () => {
            window.removeEventListener('resize', updatePopupWidth);
        };
    }, []);

    useEffect(() => {
        if (planPrices && selectedPlanID && userCount !== null && selectedDuration) {
            updateTotalPrice(userCount, selectedPlanID, selectedDuration.Duration);
        }
    }, [planPrices, selectedPlanID, userCount, selectedDuration, outstandingLicensedUser]);

    useEffect(() => {
        if (visible) {
            loading("Loading Subscription Plan...");
            const fetchCompanies = async () => {
                try {
                    const [planResponse, companyResponse, planDurationResponse, promotionsResponse] = await Promise.all([
                        baseapi.httpget('/api/register/GetGreenPlusPlans'),
                        baseapi.httpget(`/api/UserCompany/GetCompanyPlan?isUpgrade=${isUpgrade}`),
                        baseapi.httpget('/api/register/GetGreenPlusPlansDuration'),
                        baseapi.httpget('/api/register/GetGreenPlusPlansPromotion')
                    ]);

                    const planPriceData = planResponse.data.reduce((acc, plan) => {
                        acc[plan.id] = {
                            LicensedUserPrice: plan.LicensedUserPrice,
                            Fee: plan.Fee
                        };
                        return acc;
                    }, {});
                    setPlanPrices(planPriceData);

                    //set the plans 
                    setPlans(planResponse.data);


                    //set company
                    const companyData = companyResponse.data;
                    //set id 
                    setCompanyPlan(companyData);
                    setSelectedPlanID(companyData?.OutstandingInitialPlanID ?? companyData?.PlanID);
                    setInitialPlanID(companyData.PlanID);
                    setOutstandingPlanID(companyData?.OutstandingInitialPlanID ?? companyData?.PlanID);
                    setInitialDuration(companyData?.Duration)
                    setOutstandingMonths(companyData?.OutstandingMonths)
                    //set lincensed user 
                    const licensedUser = companyData?.LicensedUser || 0;
                    setUserCount(companyData?.OutstandingInitialLicensedUser ?? licensedUser);
                    setInitialUserCount(companyData?.LicensedUser);
                    setOutstandingLicensedUser(companyData?.OutstandingInitialUserCount ?? 0);


                    // Modify plan duration names to remove "+ 1 Month Free Trial" and filter out free trials
                    const filteredPlanDurationList = planDurationResponse.data
                        .filter(item => !item.IsFreeTrial)
                        .map(item => ({
                            ...item,
                            Name: item.Name.replace(/\s*\+\s*1\s*Month\s*Free\s*Trial/g, '') // Remove the " + 1 Month Free Trial" text
                        }));

                    const dropDownData = {
                        planDurationList: filteredPlanDurationList,
                        promotionList: promotionsResponse.data
                    };
                    setDropDownList(dropDownData);

                    // Select the plan duration once the planDurationList is set
                    const selectedDurationItem = dropDownData.planDurationList.find(item => item.id === companyData.PlanDurationID);


                    if (selectedDurationItem) {
                        setSelectedDuration(selectedDurationItem);
                    }


                    closeLoading();
                } catch (error) {
                    console.error('Error fetching companies:', error.message);
                }
                closeLoading();
            };

            fetchCompanies();

        }
    }, [visible]);

    const updateChargeableUsers = (userCount, planID) => {
        // Find the plan with the given ID from the plans state
        const plan = plans.find(plan => plan.id === planID);

        // If the plan is not found, assume there are no free users
        const freeUsers = plan ? plan.DefaultLicensedUser : 0;


        // Calculate the number of chargeable users
        const calculatedChargeableUsers = Math.max(userCount - freeUsers, 0);

        // Update the state for chargeable users
        setChargeableUsers(calculatedChargeableUsers);

        return calculatedChargeableUsers;
    };

    const updateTotalPrice = (userCount, planID, duration) => {

        const matchingPlanPromotion = dropDownList.promotionList.find(
            promo => promo.PlanID === planID && promo.PlanDurationID === selectedDuration?.id
        );


        const plan = planPrices[planID];

        //find the plan fee
        const planFee = plan ? matchingPlanPromotion?.PlanPrice : 0;
        setPlanFee(planFee);



        const planUserCountPrice = plan ? plan.LicensedUserPrice : 0;

        // Update chargeable users and use it for price calculation
        const calculatedChargeableUsers = updateChargeableUsers(userCount, planID);

        // Calculate the licensed user price based on the chargeable users and selected duration
        const licensedUserPricePerMonth = utils.multiply(calculatedChargeableUsers, planUserCountPrice);
        const licensedUserPrice = utils.multiply(calculatedChargeableUsers, planUserCountPrice, duration);



        // Update the userLicensePrice state with the calculated price
        setUserLicensePriceMonth(licensedUserPricePerMonth);
        setUserLicensePrice(licensedUserPrice);

        // Calculate the plan price using utils.multiply

        setPlanPriceDuration(utils.multiply(planFee, duration));

        // Calculate the total price using utils.add and utils.multiply for each operation
        const totalPrice = utils.add(
            utils.multiply(planFee, duration),
            utils.multiply(calculatedChargeableUsers, planUserCountPrice, duration)
        );

        setTotalPrice(totalPrice);

        //get the promotion 
        const matchingOutstandingPlanPromotion = dropDownList.promotionList.find(
            promo => promo.PlanID === outstandingPlanID && promo.PlanDurationID === selectedDuration?.id
        );
        const matchingOutstandingInitialPlanPromotion = dropDownList.promotionList.find(
            promo => promo.PlanID === initialPlanID && promo.PlanDurationID === selectedDuration?.id
        );
        // Calculate the outstanding amount
        const outstandingPlanFee = matchingOutstandingPlanPromotion?.PlanPrice || 0;
        const initialPlanFee = matchingOutstandingInitialPlanPromotion?.PlanPrice || 0;
        const outstandingLicensedUserPrice = planPrices[outstandingPlanID]?.LicensedUserPrice || 0;

        setPlanOutstandingFee(outstandingPlanFee)
        setInitialPlanFee(initialPlanFee)
        const outstandingAmount = utils.add(
            utils.multiply((outstandingPlanFee - initialPlanFee), outstandingMonths),
            utils.multiply(outstandingLicensedUserPrice, outstandingLicensedUser, outstandingMonths)
        );


        const calculatedOutstandingPlanPrice = utils.multiply((outstandingPlanFee - initialPlanFee), outstandingMonths);
        setOutstandingPlanPriceDuration(calculatedOutstandingPlanPrice);

        // Calculate outstanding user license price
        const calculatedOutstandingUserLicensePrice = utils.multiply(outstandingLicensedUserPrice, outstandingLicensedUser, outstandingMonths);
        setOutstandingUserLicensePrice(calculatedOutstandingUserLicensePrice);
        // Set the outstanding amount in state
        setOutstandingAmount(outstandingAmount);
    };

    // Helper function to calculate outstanding licensed users
    const calculateOutstandingLicensedUsers = (newCount, initialUserCount, selectedPlanFreeUsers, initialPlanFreeUsers) => {
        const addOnUserDifference = selectedPlanFreeUsers - initialPlanFreeUsers;
        // Calculate the number of outstanding users, ensuring it doesn't go below 0
        return Math.max(newCount - addOnUserDifference - initialUserCount, 0);
    };

    // Increment user count
    const handleIncrement = () => {
        setUserCount((prevCount) => {
            const newCount = prevCount + 1;

            // Get the free users from the selected and initial plans
            const selectedPlanFreeUsers = plans.find(plan => plan.id === selectedPlanID)?.DefaultLicensedUser || 0;
            const initialPlanFreeUsers = plans.find(plan => plan.id === initialPlanID)?.DefaultLicensedUser || 0;

            if (newCount > initialUserCount) {
                // Calculate the outstanding licensed users
                const calculatedOutstandingUsers = calculateOutstandingLicensedUsers(newCount, initialUserCount, selectedPlanFreeUsers, initialPlanFreeUsers);
                setOutstandingLicensedUser(calculatedOutstandingUsers);
            }

            return newCount;
        });
    };

    // Decrement user count
    const handleDecrement = () => {
        setUserCount((prevCount) => {
            if (prevCount > 0) {
                const newCount = prevCount - 1;

                // Get the free users from the selected and initial plans
                const selectedPlanFreeUsers = plans.find(plan => plan.id === selectedPlanID)?.DefaultLicensedUser || 0;
                const initialPlanFreeUsers = plans.find(plan => plan.id === initialPlanID)?.DefaultLicensedUser || 0;


                if (newCount > initialUserCount) {
                    // Recalculate the outstanding licensed users
                    const calculatedOutstandingUsers = calculateOutstandingLicensedUsers(newCount, initialUserCount, selectedPlanFreeUsers, initialPlanFreeUsers);
                    setOutstandingLicensedUser(calculatedOutstandingUsers);
                }

                return newCount;
            }
            return prevCount; // Return the current count if it's already 0
        });
    };

    // Effect to recalculate outstanding licensed users whenever dependencies change
    useEffect(() => {
        const updateOutstandingLicensedUser = () => {
            if (!selectedPlanID || !initialPlanID) return;

            // Get the free users from the selected and initial plans
            const selectedPlanFreeUsers = plans.find(plan => plan.id === selectedPlanID)?.DefaultLicensedUser || 0;
            const initialPlanFreeUsers = plans.find(plan => plan.id === initialPlanID)?.DefaultLicensedUser || 0;

            if (userCount > initialUserCount) {
                // Calculate the outstanding licensed users
                const calculatedOutstandingUsers = calculateOutstandingLicensedUsers(userCount, initialUserCount, selectedPlanFreeUsers, initialPlanFreeUsers);
                setOutstandingLicensedUser(calculatedOutstandingUsers);
            } else {
                setOutstandingLicensedUser(0); // Reset if user count is less or equal to initial
            }
        };

        updateOutstandingLicensedUser();
    }, [selectedPlanID, userCount, plans, initialPlanID, initialUserCount]);


    const handlePlanSelection = (planID) => {
        setSelectedPlanID(planID);
        setOutstandingPlanID(planID);
        const plan = plans.find(p => p.id === planID);
        if (plan) {
            if (userCount < plan.DefaultLicensedUser) {
                setUserCount(plan.DefaultLicensedUser);
            }
        }
    };

    const handleSubmit = () => {
        const planChanged = selectedPlanID !== initialPlanID;
        let userCountChanged = userCount !== initialUserCount;
        const durationChanged = selectedDuration.Duration !== initialDuration;
    
        let message = 'You have made the following changes:<br>';
        if (planChanged) {
            const planName = getPlanName(selectedPlanID); // Function to get plan name based on ID
            message += `<b> - Change plan to ("${planName}")</b><br>`;
        }
    
        // Force userCountChanged to true when activeTab is 'upgrade'
        if (activeTab === 'upgrade') {
            userCountChanged = true;
        }
    
        if (userCountChanged) {
            message += `<b>- ${userCount > initialUserCount ? 'Increase' : 'Decrease'} user count to ${userCount}</b><br>`;
        }
    
        // Add message for duration change
        if (durationChanged) {
            const durationMessage = getSubscriptionDurationMessage(selectedDuration.Duration);
            message += `<b> - Change subscription duration to ${durationMessage}</b><br>`;
        }
    
        // Check if a confirmation is needed
        const confirmationNeeded = activeTab !== 'upgrade' && (planChanged || userCountChanged || durationChanged);
    
        if (confirmationNeeded) {
            Swal.fire({
                title: 'Are you sure?',
                html: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, apply changes',
                cancelButtonText: 'Cancel',
            }).then(result => {
                if (result.isConfirmed) {
                    submitChanges(); // Call the submit changes function
                }
            });
        } else {
            submitChanges(); // Call the submit changes function directly
        }
    };
    
    const submitChanges = () => {
        baseapi.httppost('/api/UserCompany/SwitchCompanyPlan', {
            planID: selectedPlanID,
            licensedUserCount: userCount,
            duration: selectedDuration.Duration,
            status: activeTab,
            addOnUser: chargeableUsers,
            totalPrice: totalPrice,
            planChanged: selectedPlanID !== initialPlanID,
            userCountChanged: userCount !== initialUserCount,
            durationChanged: selectedDuration.Duration !== initialDuration,
            planDurationID: selectedDuration.id
        }).then(response => {
            const { data } = response;
            if (data.status) {
                notify(data.message, 'success', 2000);
                if (onUpdate) {
                    onUpdate();
                }
                onClose();
            } else {
                notify(data.message, 'error', 2000);
            }
        }).catch(error => {
            console.error('Error occurred while submitting:', error.message);
        });
    };

    const handleOnClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    // Function to get plan name based on ID
    const getPlanName = (planID) => {
        const plan = plans.find(p => p.id === planID);
        return plan ? plan.Name : 'Unknown';
    };

    // Determine which plans should be disabled
    const isPlanDisabled = (planID) => {
        if (!companyPlan) return false;
        const currentPlanID = companyPlan.PlanPriority;

        if (activeTab === 'upcoming') {
            return false; // Don't disable any plans
        }
        // Disable plans based on the current plan ID
        if (currentPlanID > 1) { // If the current plan ID is greater than 1 (meaning 2 or 3)
            return planID < currentPlanID; // Disable any plan with a lower ID
        }

        return false; // If the current plan ID is 1, don't disable any plans
    };

    const handleDurationChange = (e) => {
        const selectedDurationItem = dropDownList.planDurationList.find(item => item.id === e.value);

        if (selectedDurationItem) {
            // Update the selectedPlanDurationID with the ID
            setSelectedDuration(selectedDurationItem);
        }
    };
    // Helper function to get the formatted duration message
    function getSubscriptionDurationMessage(selectedDuration) {
        if (selectedDuration === 12) {
            return '1 year';
        } else if (selectedDuration % 12 === 0) {
            const years = selectedDuration / 12;
            return `${years} year(s)`;
        } else {
            return `${selectedDuration} month(s)`;
        }
    }

    return (
        <>
            <Popup
                visible={visible}
                onHiding={onClose}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                width={popupWidth}
                height={"auto"}
                title='Change your plan'
            >
                <div className="plan-selection-section">
                    <div className="plan-selection-section__plans">
                        {plans.map((plan) => {
                            // Find the matching promotion for this plan and selected duration
                            const matchingPlanPromotion = dropDownList.promotionList.find(
                                (promo) => promo.PlanID === plan.id && promo.PlanDurationID === selectedDuration?.id
                            );
                            // Use the promotion price if found, otherwise fallback to plan.Fee
                            const planPromotionPrice = matchingPlanPromotion ? matchingPlanPromotion.PlanPrice : plan.Fee;
                            const planOriginalPrice = matchingPlanPromotion ? matchingPlanPromotion.OriginalPrice : plan.Fee;
                            return (
                                <div
                                    key={plan.id}
                                    className={`plan-selection-section__plan ${selectedPlanID === plan.id ? 'plan-selection-section__plan-selected' : ''} ${isPlanDisabled(plan.PlanPriority) ? 'plan-disabled' : ''}`}
                                    onClick={() => !isPlanDisabled(plan.PlanPriority) && handlePlanSelection(plan.id)}
                                >
                                    <div className="plan-selection-wrapper">
                                        <div className="plan-selection-section__plan-name">{plan.Name}</div>
                                        <div className="plan-selection-section__plan-description">{plan.Description}</div>
                                    </div>
                                    {/* Display the promotion price or the plan fee */}
                                    <div>
                                        <div className="plan-selection-section__plan-price">
                                            <span className="price-green">RM {planPromotionPrice}</span> / month
                                        </div>
                                        <div className="plan-selection-section__plan-original-price">RM {planOriginalPrice}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <hr className="hr-vertical" />

                    <div className="plan-selection-section__users">
                        <div className="plan-selection-section__users-title">Total User(s)</div>
                        <div className="plan-selection-section__user-counter">
                            <button
                                className={`plan-selection-section__user-decrement ${(activeTab === "upgrade" && userCount === companyPlan?.LicensedUser) ||
                                    (activeTab === "upcoming" && selectedPlanID !== null && plans.find(plan => plan.id === selectedPlanID)?.DefaultLicensedUser >= userCount)
                                    ? 'disabled'
                                    : ''
                                    }`}
                                onClick={handleDecrement}
                                disabled={
                                    (activeTab === "upgrade" && userCount === companyPlan?.LicensedUser) ||
                                    (activeTab === "upcoming" && selectedPlanID !== null && plans.find(plan => plan.id === selectedPlanID)?.DefaultLicensedUser >= userCount)
                                }
                            >
                                <i className="fa fa-minus"></i>
                            </button>
                            <div className="plan-selection-section__user-number">{userCount}</div>
                            <button
                                className="plan-selection-section__user-increment"
                                onClick={handleIncrement}
                            >
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                        <div className="plan-selection-section__total-price">
                            <b><span className="price-highlight">RM {userLicensePriceMonth}</span></b> per month total
                        </div>
                        <div className="company-selection-buttons">
                            <button className="company-selection-cancel-btn" onClick={handleOnClose}>Cancel</button>
                            <button className="company-selection-continue-btn" onClick={handleSubmit}>Continue</button>
                        </div>
                    </div>
                </div>

                <div className="plan-selection-section">
                    <div className="plan-selection-section__plans">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="duration-selectbox" style={{ width: '150px', marginRight: '20px' }}>Plan Duration</label>
                            <SelectBox
                                id="duration-selectbox"
                                dataSource={dropDownList.planDurationList}
                                onValueChanged={handleDurationChange}
                                value={selectedDuration?.id}
                                style={{ flex: '1' }} // Takes up remaining space
                                displayExpr="Name"
                                valueExpr="id"
                                disabled={activeTab === "upgrade"}
                                dropDownOptions={{
                                    height: 'auto', // Automatically adjusts to the content height
                                    minHeight: 138, // Limit height to avoid overflow

                                }}
                                itemRender={(data) => {
                                    const hasDiscount = data.DiscountPercentage && data.DiscountPercentage > 0;
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span>{data.Name}</span>
                                            {hasDiscount && (
                                                <span className="duration-selectbox-discount" style={{ marginLeft: '10px' }}>
                                                    Up To {data.DiscountPercentage}% Off
                                                </span>
                                            )}
                                        </div>
                                    );
                                }}
                            />

                        </div>

                        <br />

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="subscription-plan" style={{ width: '150px', marginRight: '20px' }}>Subscription Plan</label>
                            <TextBox
                                id="subscription-plan"
                                value={`RM ${isUpgrade ? utils.numberToCurrency(outstandingPlanPriceDuration) : utils.numberToCurrency(planPriceDuration)}`}
                                alignment="left"
                                style={{ flex: '1' }} // Takes up remaining space
                                readOnly={true}
                            />
                        </div>

                        <br />


                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="additional-user" style={{ width: '150px', marginRight: '20px' }}>Additional User(s)</label>
                            <TextBox
                                id="additional-user"
                                value={`RM ${isUpgrade ? utils.numberToCurrency(outstandingUserLicensePrice) : utils.numberToCurrency(userLicensePrice)}`}
                                alignment="left"
                                style={{ flex: '1' }} // Takes up remaining space
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <hr className="hr-vertical" />
                    <div className="plan-selection-section__users">



                        <div className="popup-subscription-plan-container">
                            {activeTab === 'upcoming' ? (
                                <>
                                    <div className="popup-subscription-total-text">Total</div>
                                    <div className="price-highlight">RM {utils.numberToCurrency(totalPrice || 0)}</div>
                                    <div className="subscription-plan-title">
                                        &nbsp; [ RM {planFee || 0} x {selectedDuration?.Duration || 0} month(s) ]
                                        + [ RM {planPrices[selectedPlanID]?.LicensedUserPrice || 0} x {chargeableUsers || 0} User(s) x {selectedDuration?.Duration || 0} month(s) ]
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="popup-subscription-total-text">Outstanding</div>
                                    <div className="price-highlight-red">RM {utils.numberToCurrency(outstandingAmount || 0)}</div>
                                    <div className="subscription-plan-title">
                                        &nbsp; [ RM {planOutstandingFee - (planIntialFee || 0) || 0} x {outstandingMonths || 0} month(s) ]
                                        + [ RM {planPrices[outstandingPlanID]?.LicensedUserPrice || 0} x {outstandingLicensedUser || 0} User(s) x {outstandingMonths || 0} month(s) ]
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

            </Popup>
        </>
    );
};

export default PlanSelectionForm;


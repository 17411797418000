import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import { Column, Editing, Popup, Form, Item, RequiredRule, Button as ButtonColumn, AsyncRule } from 'devextreme-react/data-grid';
import utils from '../../utils/common';

class Salesmen extends React.Component {
    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="Salesman"
                    apiURL="/api/Salesman"
                    listingURL="/api/Salesman/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    storageName={"SettingSalesmenListing"}
                />
            </div>
        </Container>
    }
}

export default Salesmen;
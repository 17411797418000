import React, { useEffect, useState, useRef } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import SalesQuotationForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import Transfer from "../transform/Transform";
import DropDownButton from "devextreme-react/drop-down-button";

export default function SalesCreditNoteListing() {

    const apiURL = `/api/SalesCreditNote`;
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });
    const [currentEditID, setCurrentEditID] = useState(null);
    const [transformTitle, setTransformTitle] = useState(null);
	const [transferData, setTransferData] = useState(null);
	const transformRef = useRef(null);

    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
    }, []);

    function handleEdit(e){
        const id = e.row.key;
        setCurrentEditID(id);
    }

    const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };

    const onItemClick = (e) => {
		const { action } = e.itemData;
		setTransformTitle(action);
		utils.transformOpen(transformRef);
	};
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="sales-listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/SalesCreditNote/list"
            sortColumn={"DocumentNo"}
            listingTitle={"Credit Note"}
            storageName={"SalesCreditNoteListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
            customizedAddButton={
                <DropDownButton
                    className='customized-listing-add-btn sales-listing-btn'
                    splitButton={true}
                    useSelectMode={false}
                    icon='add'
                    text='Add'
                    items={[
                        { id: 1, action: "Transfer from Sales Invoice" },
                        { id: 2, action: "Transfer from Cash Sales" }
                    ]}
                    displayExpr='action'
                    keyExpr='id'
                    width={"100px"}
                    dropDownOptions={{ width: "190px" }}
                    onItemClick={onItemClick}
                    onButtonClick={() => {
                        setCurrentEditID("new");
                    }}
                />
            }
        >
            <Column dataField="DocumentNo" />
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="CustomerCode" />
            <Column dataField="CustomerName" />
            <Column dataField="ForexID" caption={"Forex"} >
                <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>

        <SalesQuotationForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            apiURL={apiURL}
            title={"Credit Note"}
            transferData={transferData}
            clearTransfer={setTransferData}
        />
        
        <Transfer 
            ref={transformRef} 
            title={transformTitle} 
            apiPath={{
                "Transfer from Sales Invoice": {
                    FullDocumentListing: "api/SalesTransfer/InvoiceFullDocument",
                    PartialDocumentListing: "api/SalesTransfer/InvoicePartial",
                    FullDocumentTransfer: "api/SalesTransfer/InvoiceFullTransfer",
                    PartialDocumentTransfer:  "api/SalesTransfer/InvoicePartialTransfer",
                    FilterPath: "api/SalesTransfer/InvoiceFilter"
                },
                "Transfer from Cash Sales": {
                    FullDocumentListing: "api/SalesTransfer/CashSalesFullDocument",
                    PartialDocumentListing: "api/SalesTransfer/CashSalesPartial",
                    FullDocumentTransfer: "api/SalesTransfer/CashSalesFullTransfer",
                    PartialDocumentTransfer:  "api/SalesTransfer/CashSalesPartialTransfer",
                    FilterPath: "api/SalesTransfer/CashSalesFilter"
                },
            }}
            transferData={transferFormData}
        />
    </Container>;
}

import { useEffect, useRef, useState, forwardRef, useCallback, useMemo } from "react";

import StockReceivedForm from './StockReportListing';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea, SelectBox, DropDownBox, Popover } from "devextreme-react";
import { Button } from "devextreme-react/button";
import CustomizedLookup from "../../../components/lookup/Lookup";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { stockOptions, stockGroupOptions, itemTypeOptions, brandOptions, categoryOptions, workshopModel, locationOptions } from "../../../utils/lookupstore";
import { DataGrid, Button as Pager, Toolbar, Column, Selection, MasterDetail, Paging, Summary, TotalItem, ValueFormat, } from "devextreme-react/data-grid";
import StockAdjustmentForm from "../adjustment/form";
import StockIssueForm from "../issue/form";
import StockReceiveForm from "../received/form";
import StockTakeForm from "../take/form";
import StockTransferForm from "../transfer/form";
import StockWriteOff from "../write-off/form";
import StockTransferFrom from "../transfer/form";
import StockWriteOffForm from "../write-off/form";
import StockReportListing from "./StockReportListing";


import DOForm from "../../Sales-Module/delivery-order/form";
import INVForm from "../../Sales-Module/invoice-main/form";
import CashSales from "../../Sales-Module/cash-sales/form";
import SalesDebitNote from "../../Sales-Module/debit-note/form";
import SalesCreditNote from "../../Sales-Module/credit-note/form";


import PurchaseReceive from "../../Purchase-Module/received/form";
import PurchaseInvoice from "../../Purchase-Module/invoice/form";
import CashPurchase from "../../Purchase-Module/cash-purchase/form";
import PurchaseDebitNote from "../../Purchase-Module/debit-note/form";
import ReturnPurchase from "../../../pages/Purchase-Module/purchase-return/form";


export default function StockBalanceListing() {


    const [mainDataSource, setMainDataSource] = useState([]);
    const [detailDataSource, setDetailDataSource] = useState([]);
    const [lockForm, setLockForm] = useState(false);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentPrefix, setCurrentEditPrefix] = useState(null);
    const formID = useRef(null);
    const [listingTransactionSource, setListingTransactionSource] = useState([]);
    const [originalListingTransactionSource, setOriginalListingTransactionSource] = useState([]);
    const stockReportListingRef = useRef(null);

    function handleEdit(e) {
        const id = e.row.key;
        setLockForm(utils.isLockedForm(e));
        setCurrentEditID(id);
    }
    const handleFormSubmitSuccess = () => {
        loading("Loading Latest Stock Details...");

        baseapi.httpget("/api/StockDetailAnalysis/GetAnalysisTransaction?id=" + formID.current)
            .then((response) => {
                const { data } = response;

                setListingTransactionSource(data.transactions);
                setOriginalListingTransactionSource(data.transactions);

            })
            .catch((error) => {
                console.error('Error fetching stock details:', error);
            })
            .finally(() => {

                setTimeout(() => {
                    closeLoading();
                }, 300);

            });
    };

    const [currentForm, setCurrentForm] = useState(null);
    const handleRowClick = (data) => {
        setCurrentEditID(null);
        setCurrentEditPrefix(null);
        loading();
        setCurrentForm(null);
        setTimeout(() => {

            closeLoading();
        }, 300);
        setTimeout(() => {
            setCurrentEditID(data.DocumentID);
            setCurrentEditPrefix(data.DocumentPrefix);
        }, 0);
        // Using a setTimeout with a short delay to ensure state is updated correctly

    };

    return <Container>

        <div>
            <div className='listing-page-title-container'>
                <span className='datagrid-customized-title'>
                    <PathIndicator />
                </span>
            </div>

            <StockReportListing
                ref={stockReportListingRef}
                reportName={"Stock Balance"}
                reportID={"Stock Balance"}
                ReturnResult={(e) => {
                    setMainDataSource(e.MainData);
                    setDetailDataSource(e.Details.flat());
                }}
            />
            <div style={{ marginTop: '20px' }}>
                <div className='popup-group-form-item'>

                    <DataGrid width={"170vh"} dataSource={mainDataSource} showBorders={true} columnAutoWidth={true} paging={{ enabled: false }} sorting={{ mode: "multiple" }} // Enable sorting
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                    >
                        <Column dataField='StockCode' editorOptions={{ disabled: true }}  width="150px"/>
                        <Column dataField='StockDescription' caption="Description" editorOptions={{ disabled: true }} width="280px" />
                        <Column dataField='StockGroupCode' caption="Stock Group"editorOptions={{ disabled: true }} />
                        <Column dataField='ItemTypeCode' caption="Item Type"editorOptions={{ disabled: true }} />
                        <Column dataField='ShelfCode' caption="Shelf" editorOptions={{ disabled: true }} />
                        <Column dataField='LocationCode' caption="Location" editorOptions={{ disabled: true }}  width="100px"/>
                        <Column dataField='NetQuantityFormat' caption="Net Qty" editorOptions={{ disabled: true }} width="100px" />
                        <Column dataField='TotalCost' editorOptions={{ disabled: true }}   format={{ type: 'fixedPoint', precision: 2 }}/>
                        <Column dataField='AverageCost' editorOptions={{ disabled: true }}  format={{ type: 'fixedPoint', precision: 2 }}/>
                        <Paging defaultPageSize={15} />
                    </DataGrid>

                </div>

            </div>
           
        </div>

    </Container>



}



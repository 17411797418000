import { TextBox } from "devextreme-react";
import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import utils from "../../../../utils/common";

export default forwardRef(function FixedAssetsForm(props, ref) {
	const emptyForm = {
		Parent: {},
		Children: {}
	};
	const [isEditing, setIsEditing] = useState(null);
	const [startValidation, setStartValidation] = useState(0);
	const [formValue, setFormValue] = useState(emptyForm);
	const tempForm = useRef(emptyForm);
	const [isRoot, setIsRoot] = useState(false);

	useEffect(() => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged(formValue);
		}
	}, [formValue]);

	useEffect(() => {
		if (props.clearForm) {
			tempForm.current = emptyForm;
			setFormValue(tempForm.current);
		}
	}, [props.clearForm]);

	useEffect(() => {
		if (props.isEditing !== undefined) {
			setIsEditing(props.isEditing);
		}
	}, [props.isEditing]);

	useEffect(() => {
		if (props.startValidation !== undefined) {
			setStartValidation(props.startValidation);
		}
	}, [props.startValidation]);

	useEffect(() => {
		if (props.validationReady !== undefined && startValidation !== 0) {
			props.validationReady(true);
		}
	}, [startValidation]);

	useImperativeHandle(ref, () => ({
		setValue(value) {
			tempForm.current = value;
			setFormValue(tempForm.current);
		},
		getDepAccParentID() {
			const node = props.node;
			const data = node.data;
			const nodeParent = node.parent;
			var accumDepreID = null;

			//Set AccumParentID
			if (node["level"] == 1 && !isEditing && data["SpecialAccountType"] !== "AD") {
				accumDepreID = nodeParent["children"].find((c) => c.data["AccumParentID"] === node["key"])?.key;
			}

			return accumDepreID;
		}
	}));

	useEffect(() => {
		if (!utils.isNullOrEmpty(props.node)) {
			const node = props.node;

			if (node["level"] === 0) {
				setIsRoot(true);
			} else {
				setIsRoot(false);
			}
		}
	}, [props.node]);

	function valueOnChange(e, children = null) {
		try {
			const dataField = children !== null ? children.element.getAttribute("dataField") : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };

			if (children === null) {
				copied["Parent"][dataField] = e.value.toUpperCase();

				//Add shortcut text to the Accum. Deprn description
				if (dataField === "Name" && !utils.isNullOrEmpty(e.value)) {
					copied["Children"]["Name"] = `Accum. Deprn. - ${utils.toUpperCase(e.value)}`;
				}
			} else {
				copied["Children"][dataField] = utils.toUpperCase(children.value);
			}
			tempForm.current = copied;
			setFormValue(tempForm.current);
		} catch (err) {
			console.log(err);
		}
	}

	if (isEditing && isRoot) {
		return (
			<div className='popup-form-item-container1'>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Description: </div>

					<div className='popup-group-form-input'>
						<TextBox
							value={formValue["Parent"]["Name"]}
							elementAttr={{ dataField: "Name" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"auto"}>
							<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
						</TextBox>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div>
				<div className='popup-form-subtitle'>Cost</div>

				<div className='popup-form-item-container1'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Code: </div>

						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["Parent"]["Code"]}
								elementAttr={{ dataField: "Code" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}>
								<Validator>{startValidation !== 0 && <ValidatorRequired message={"Code is required!"} />}</Validator>
							</TextBox>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Description: </div>

						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["Parent"]["Name"]}
								elementAttr={{ dataField: "Name" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}>
								<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
							</TextBox>
						</div>
					</div>
				</div>

				{!isEditing && (
					<div>
						<div className='popup-form-subtitle'>Accumulated Deprn. Account</div>

						<div className='popup-form-item-container1'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Code: </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Children"]["Code"]}
										elementAttr={{ dataField: "Code" }}
										onValueChanged={(e) => {
											valueOnChange(null, e);
										}}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Code is required!"} />}</Validator>
									</TextBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Description: </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["Children"]["Name"]}
										elementAttr={{ dataField: "Name" }}
										onValueChanged={(e) => {
											valueOnChange(null, e);
										}}
										alignment='left'
										width={"auto"}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
									</TextBox>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
});

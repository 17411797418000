import React, { useEffect, useState, useRef } from "react";
import baseapi from "../../api/baseapi";
import { Column } from "devextreme-react/data-grid";
import utils, { loading, closeLoading } from "../../utils/common";
import PathIndicator from "../path-indicator/PathIndicator";
import Listing from "./newListing";
import SettingForm from "../../pages/settings/SettingForm";

export default function SettingListing(props) {
	const [currentEditID, setCurrentEditID] = useState(null);

	const handleEdit = (e) => {
        const id = e.row.key;
		setCurrentEditID(id);
    }

	return (
		<div>
			<Listing
				className={"listing-page-datagrid ".concat(props.className !== undefined ? props.className : "")}
				width={props.width !== undefined ? props.width : "100%"}
				height={props.height}
				apiURL={props.apiURL}
				listingURL={props.listingURL}
				listingTitle={props.listingTitle}
				sortColumn={utils.isNullOrEmpty(props.sortColumn) ? "Code" : props.sortColumn}
				storageName={utils.isNullOrEmpty(props.storageName) ? null : props.storageName}
				onAddClick={setCurrentEditID}
            	handleEdit={handleEdit}
			>

				{props.defaultSettingTemplate !== true && props.children}

				{/* Default Template Columns */}
				{props.defaultSettingTemplate !== false && <Column dataField='Code' dataType='string' 
					cellTemplate={(container, options) => {
                        // Render the code text
                        container.textContent = options.data.Code;
                        // If the Default field is true, append the word "default"
                        if (options.data.IsDefault) {
                            const defaultSpan = document.createElement("span");
                            defaultSpan.classList.add("default-option-label");
                            defaultSpan.textContent = "Default";
                            container.appendChild(defaultSpan);
                        }
                    }}
                width={250} />}
				{props.defaultSettingTemplate !== false && <Column dataField='Name' dataType='string' />}
				{props.defaultSettingTemplate !== false && (
					<Column
						dataField='Cancelled'
						caption={"Active"}
						dataType='string'
						customizeText={(v) => {
							return v.value ? "Yes" : "No";
						}}
					/>
				)}

				{props.defaultSettingTemplate && <Column dataField='Modified' format='dd/MM/yyyy' dataType={"date"} />}

				{/* End of Default Template Column */}
			</Listing>

			<SettingForm 
				formID={currentEditID}
				closePopup={setCurrentEditID}
				title={props.listingTitle}
				apiURL={props.apiURL}
				defaultSettingTemplate={props.defaultSettingTemplate}
			/>

		</div>
	);

	// add-btn background color based on listingTitle
	function getBackgroundColorClass() {
		const className = props.className;
		let btn_backgroundColorClass = "";
		console.log(className);

		switch (className) {
			case "sales-listing-datagrid":
				btn_backgroundColorClass = "sales-listing-btn";
				break;

			case "stock-listing-datagrid":
				btn_backgroundColorClass = "stock-listing-btn";
				break;

			case "workshop-listing-datagrid":
				btn_backgroundColorClass = "workshop-listing-btn";
				break;

			default:
				// Default background color class for add btn
				btn_backgroundColorClass = "listing-page-add-btn";
				break;
		}

		return btn_backgroundColorClass;
	}
};
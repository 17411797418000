import React, { useEffect, useState, useRef } from "react";
import baseapi from "../../../api/baseapi";
import Container from "../../../components/container";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { DateBox } from "devextreme-react/date-box";
import utils from "../../../utils/common";
import {
    TreeList, Scrolling, Paging, Pager, Column, Lookup,
} from 'devextreme-react/tree-list';
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomStore from "devextreme/data/custom_store";
import WorkshopInvoiceForm from "../Invoice/form";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import { loading, closeLoading } from "../../../utils/common";
export default function VehicleServiceRecordListing() {
    const [listingDataSource, setListingDataSource] = useState(null);
    const [registrationList, setRegistrationList] = useState([]);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [regNoValue, setRegNoValue] = useState(null);
    const [mechanicValue, setMechanicValue] = useState(null);
    const treelistRef = useRef(null);
    const searchBoxRef = useRef(null);
    const [fromDate, setFromDate] = useState(
		new Date(new Date().setDate(1)).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [toDate, setToDate] = useState(
		new Date(new Date()).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
    const regNoLookupRef = useRef(null);
    const [mechanicList, setMechanicList] = useState([]);
    const mechanicLookupRef = useRef(null);
    const [disablePrint, setDisablePrint] = useState(true);
	const currentToken = localStorage.getItem("Authorization").substring(7);

    const refresh = () => {
        setListingDataSource({
            store: new CustomStore({
                key: "ID",
                load: () => {
                    return baseapi
                        .httpget(`api/WKInvoice/GetInvoiceByRegID`, {
                            regID: regNoValue,
                            mechanicID: mechanicValue,
                            fromDate: fromDate.current,
                            toDate: toDate.current
                        })
                        .then((response) => {
                            const { data } = response;
                            if (data !== null) {
                                let completeArray = [];
                                let invoiceMainArray = [];
                                let invoiceDetailsArray = [];
                                for (let i = 0; i < data.length; i++) {
                                    invoiceMainArray.push(data[i].InvoiceMain);
                                    invoiceDetailsArray = invoiceDetailsArray.concat(data[i].InvoiceDetail);
                                }
                                completeArray = invoiceMainArray.concat(invoiceDetailsArray);
                                if(completeArray.length > 0) {
                                    setDisablePrint(false);
                                }
                                return completeArray;
                            } else {
                                return [];
                            }
                        })
                        .catch(() => {
                            throw "Network error";
                        });
                }
            })
        });

    };

    const directToReport = (e) => {
		baseapi.httpget(`${utils.ReportURL}/api/Report/Get?accessToken=${currentToken}`).then((response) => {
			const { data } = response;
			for (var i = 0; i < data.length; i++) {
				if (data[i].ID === "pYiTjXp8eTU%3d") {
					var url = data[i].ViewerAccessURL;

                    url = url + `&FromDate=${utils.dateboxFormat(fromDate)}&ToDate=${utils.dateboxFormat(toDate)}`;

                    if (!utils.isNullOrEmpty(mechanicValue)) {
                        url = url + `&MechanicDetail=${mechanicValue}`;
                    }

                    if (!utils.isNullOrEmpty(regNoValue)) {
                        url = url + `&RegisterNo=${regNoValue}`;
                    }

					loading("Redirecting You To The Report");
					window.open(url, "_blank");
					closeLoading();
				}
			}
		});
	};

    function handleSubmit() {

        loading("Fetching Filter Result...");
        refresh();
        closeLoading();
    }

    const allowedPageSizes = [10, 25, 50, 100];

    return (
        <Container>
            <div className='listing-page-title-container'>
                <span className='datagrid-customized-title'>
                    <PathIndicator />
                </span>
            </div>
            <div className='custom-form-grid-section-33-33-33 workshop-module-parent' style={{ marginLeft: 0, marginTop: 0 }}>

                <div className='popup-form-item-container'>
                    <div className='popup-group-form-item'>

                        <div className='popup-group-form-label'>Reg No </div>
                        <div className='popup-group-form-input'>
                            <CustomizedLookup
                                ref={regNoLookupRef}
                                className={"listing-page-searching-lookup"}
                                dataSource={registrationList}
                                displayExpr={"code"}
                                valueExpr={"id"}
                                height={"36px"}
                                onSelectionChanged={(e) => setRegNoValue(e.value)}
                                dataSourceURL={"api/Utilities/GetWKRegistration"}
                                onDataSourceChanged={setRegistrationList}
                            >
                                <Column dataField='code'></Column>
                                <Column dataField='modelCode' caption='Model'></Column>
                                <Column dataField='customerName' caption='Customer'></Column>
                                <Column dataField='mileage'></Column>
                            </CustomizedLookup>
                        </div>


                    </div>

                </div> <div></div><div></div>
                <div className='popup-form-item-container'>
                    <div className='popup-group-form-item'>

                        <div className='popup-group-form-label'>Mechanic By Item </div>
                        <div className='popup-group-form-input'>

                            <CustomizedLookup
                                ref={mechanicLookupRef}
                                className={"workshop-listing-datagrid"}
                                dataSource={mechanicList}
                                displayExpr={"name"}
                                valueExpr={"id"}
                                dataSourceURL={"api/Utilities/GetWKMechanic"}
                                onDataSourceChanged={setMechanicList}
                                onSelectionChanged={(e) => setMechanicValue(e.value)}
                            >
                                <Column dataField='code'></Column>
                                <Column dataField='name'></Column>
                            </CustomizedLookup>

                        </div>


                    </div>

                </div>  <div></div><div></div>

                <div className='popup-form-item-container'>
                    <div className='popup-group-form-item'>

                        <div className='popup-group-form-label'>From </div>
                        <div className='popup-group-form-input'>
                            <DateBox
                                displayFormat='dd/MM/yyyy'
                                dateSerializationFormat='yyyy-MM-dd'
                                useMaskBehavior={true}
                                type='date'
                                showClearButton={true}
                                value={fromDate}
                                onValueChanged={(e) => {
                                    setFromDate(e.value);
                                }}
                            />
                        </div>


                    </div>

                </div>
                <div className='popup-form-item-container'>
                    <div className='popup-group-form-item'>

                        <div className='popup-group-form-label'>To:</div>
                        <div className='popup-group-form-input'>
                            <DateBox
                                displayFormat='dd/MM/yyyy'
                                dateSerializationFormat='yyyy-MM-dd'
                                useMaskBehavior={true}
                                type='date'
                                showClearButton={true}
                                value={toDate}
                                onValueChanged={(e) => {
                                    setToDate(e.value);
                                }}
                            />
                        </div>


                    </div>

                </div>
                <div></div>
                <div className='popup-form-item-container' style={{ marginTop: "20px" }}>
                    <div className='popup-group-form-item'>

                        <Button text='Inquiry' type='success' width={"auto"} onClick={(e) => handleSubmit(e)} />
                        <Button text='Print Report' disabled={disablePrint} type='success' stylingMode={"outlined"} 
                        width={"auto"} style={{ marginLeft: "12px", backgroundColor: "white", color: "#46CF64" }} onClick={() => directToReport()} />
                    </div>
                </div>
            </div>
            <TextBox
                width={"450px"}
                height={"36px"}
                ref={searchBoxRef}
                className='listing-page-search-box'
                placeholder='Search'
                valueChangeEvent='keyup'
                onValueChanged={(e) => {
                    treelistRef.current.instance.searchByText(e.value);
                }}
            />
            <TreeList
                id='vehicle-access-treelist '
                ref={treelistRef}
                className='listing-page-treelist margin-top34'
                dataSource={listingDataSource}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={false}
                columnResizingMode='nextColumn'
                showRowLines={true}
                showBorders={false}
                autoExpandAll={true}
                filterMode={'fullBranch'}
                height={"65vh"}
                width={"100%"}
                keyExpr='ID'
                parentIdExpr='TreeListDocNoRef'
                rootValue={0}
            >
                <Paging
                    enabled={true}
                    defaultPageSize={25} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true} />
                <Column
                    dataField='DocumentNo'
                    caption={"Doc No"}
                    width={130}
                    cellRender={(e) => {
                        const data = e.data;
                        const id = e.key;

                        if (data.DocumentNo !== undefined) {
                            return (
                                <span
                                    className='clickable-span-tag'
                                    onClick={() => {
                                        setCurrentEditID(id);
                                    }}>
                                    {e.displayValue}
                                </span>
                            );
                        } else {
                            return e.displayValue;
                        }
                    }}
                />
                <Column
                    dataField='DocumentDate'
                    caption={"Date"}
                    width={95}
                    cellRender={(e) => {
                        const data = e.data;
                        if (data.DocumentNo !== undefined) {
                            return <span className='highlight-span-tag'>{utils.dateFormatter(e.displayValue)}</span>;
                        }
                    }}
                />
                <Column
                    dataField='Mileage'
                    width={95}
                    cellRender={(e) => {
                        const data = e.data;
                        if (data.DocumentNo !== undefined) {
                            return <span className='highlight-span-tag'>{e.displayValue}</span>;
                        }
                    }}
                />
                <Column
                    dataField='Description'
                    dataType={"string"}
                    caption={"Part/Description"}
                    width={390}
                    cellRender={(e) => {
                        if (utils.isNumberString(e.value)) {
                            return <span className='highlight-span-tag'>{"Grand Total: " + utils.numberToCurrency(e.value)}</span>;
                        } else {
                            return e.value;
                        }
                    }}
                />
                <Column dataField='Quantity' caption={"Qty"} width={60} />
                <Column dataField='UnitPrice' caption={"U/Price"} width={105} format={{ type: "fixedPoint", precision: 2 }} />
                <Column
                    dataField='TaxInclusive'
                    visible={false}
                    caption={"SST"}
                    dataType={"string"}
                    width={95}
                    customizeText={(v) => {
                        if (v.value === true) {
                            return "Inclusive";
                        } else if (v.value === false) {
                            return "Exclusive";
                        } else {
                            return "";
                        }
                    }}
                />
                <Column dataField='ForexTaxable' caption={"Net/Price"} width={105} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField='ItemDiscount' caption={"Discount"} width={135} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField='NettAmount' caption={"Amount"} width={135} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField='MechanicName' caption={"MechanicName"} width={186} />

                <Scrolling
                    mode="standard" />
            </TreeList>

            <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} title={"Invoice Entry"} apiURL={"/api/WKInvoice"} />
        </Container>
    );
}

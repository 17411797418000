import { useEffect, useRef, useState, useCallback } from "react";
import utils from "../../../utils/common";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea } from "devextreme-react";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../../utils/module-calculation";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import { uomOptions } from "../../../utils/lookupstore";
import StockNegativeValueForm from "../../../components/popup-form/StockNegativeValueForm";
export default function StockTransferFrom(props) {
	const tempForm = useRef(null);
	const formID = useRef(null);
	const childrenGridRef = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);
	const [shelfList, setShelfList] = useState([]);
	const [fromLocationList, setFromLocationList] = useState([]);
	const [toLocationList, setToLocationList] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [formValue, setFormValue] = useState({});
	const [taxCodeList, setTaxCodeList] = useState([]);
	const [uomList, setUomList] = useState([]);
	const [stockList, setStockList] = useState([]);
	const [forexList, setForexList] = useState([]);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const [defaultValue, setDefaultValue] = useState({});

	const forexLookupRef = useRef(null);
	const isRowBeingAddedRef = useRef(false);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			}
			else {
				setIsEditing(false);
			}

			if (utils.isEmptyArray(uomList)) {
				uomOptions.getAll().then((list) => {
					setUomList(list.data);
				});
			}
		}
	}, [props.formID]);

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Stock Transfer...");
		else loading("Loading Existing Stock Transfer...");

		baseapi.httpget("/api/StockTransfer/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if(!utils.isNullOrEmpty(data.defaultValues)){
				setDefaultValue(data.defaultValues);
			}

			if (formID.current === "new") {
				const copiedParent = { ...data.model.Parent };

				tempForm.current = copiedParent;
				setFormValue(copiedParent);
				console.log(copiedParent, "copiedParent")
				utils.childrenGridAddRow(childrenGridRef); //Add row for the frist grid
			}
			else {
				utils.childrenGridSetSource(childrenGridRef, data.model.Children);
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
			}

			closeLoading();
			formRead.current = true;
		});
	}

	const onInitNew = () => {
		let lastItem = null;
		// Check if childrenGridRef is not null or undefined

		const items = utils.childrenGridGetSource(childrenGridRef);
		lastItem = items.length > 0 ? items[items.length - 1] : null;
		// Define the base new row object
		const newRow = {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			BankInDate: new Date(),
			NettAmount: 0,
			NettLocalAmount: 0,
			BankCharges: 0,
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
			DetailLN: 0,
			Point: 0,
			Quantity: 0
		};

		// If there is a last item, copy the FromLocationID and ToLocationID to the new row
		if (lastItem) {
			return Object.assign({}, newRow, {
				FromLocationID: lastItem.FromLocationID,
				ToLocationID: lastItem.ToLocationID
			});
		}


		// If there is no last item, return the base new row object
		return newRow;
	};



	const onEditorPreparing = (e) => {
		if (e.dataField === 'ToQuantity' && e.parentType === 'dataRow') {
			// Use the current value of the ref to check if a row is being added
			console.log("isRowBeingAdded", isRowBeingAddedRef.current);
			if (!isRowBeingAddedRef.current && childrenGridRef.current) {

				childrenGridRef.current.addRow();
				// Set the ref to true after adding a row
				isRowBeingAddedRef.current = true;
			}
		}
	};

	const onRowUpdating = (e) => {


		if (utils.childrenGridLength(childrenGridRef) === e.oldData.Line && e.newData.ToQuantity !== null && e.newData.ToQuantity !== 0) {
			// Set the flag to false only if 'ToQuantity' has a value
			isRowBeingAddedRef.current = false;

		}
		if (e.newData.ToQuantity < 0) {
			e.newData.ToQuantity = 0; // Set the Quantity to 0 if a negative value is entered
			utils.popupFormSetErrorForm(formRef, {
				visible: true,
				message: "Negative values for transfer quantity are not allowed",
				type: "Warning"
			});
		}

	};
	function calculateTotal(gridData) {
		return gridData.reduce((acc, row) => acc + (row.Quantity * row.UnitCost), 0);
	}
	const onRowUpdated = (e, children = null) => {


		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		var totalLocal = 0;

		if (Array.isArray(childrenDetails)) {
			for (var i = 0; i < childrenDetails.length; i++) {
				const row = childrenDetails[i];

				//Reset the line
				if (!utils.isNullOrEmpty(row["Line"])) {
					row["Line"] = i + 1;
				}

				if (!utils.isNullOrEmpty(row["ToQuantity"])) {
					totalLocal = utils.add(totalLocal, row["ToQuantity"] * row["UnitCost"]);
					formValue["MainLocalAmount"] = totalLocal;

					setFormValue(prevState => {
						return { ...prevState, MainLocalAmount: totalLocal };
					});
				}
			}
		}



	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const selectedItem = e.selectedItem;
		console.log("selecteditem", selectedItem)
		const dataSource = e.dataSource;
		currentRecords[rowIndex][columnName] = e.value;
		const stockID = currentRecords[rowIndex]["StockID"];
		const locationID = currentRecords[rowIndex]["FromLocationID"]; // Assuming tempForm contains the LocationID
		if (columnName === "StockID") {
			currentRecords[rowIndex]["StockCode"] = selectedItem?.code;
			currentRecords[rowIndex]["Description"] = selectedItem?.name;
			currentRecords[rowIndex]["Description2"] = selectedItem?.description2;
			currentRecords[rowIndex]["MoreDescription"] = selectedItem?.moreDescription;
			currentRecords[rowIndex]["UOMID"] = selectedItem?.uomID;
			currentRecords[rowIndex]["UOM"] = selectedItem?.uomCode;

			baseapi.httpget(`/api/Utilities/GetStockTotalQuantity?StockID=${e.value}`)
				.then((response) => {
					if (response.status === 200) {
						const stockData = response.data.length > 0 ? response.data[0] : { unitCost: 0.00 }; // Check if data is empty and set unitCost to 0 if it is
						currentRecords[rowIndex]["UnitCost"] = stockData.unitCost;
					} else {
						console.error('stock not found');
					}
				});
			const copiedArr = [...stockList];
			if (!utils.isInArray(stockList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setStockList(copiedArr);


			baseapi.httpget(`/api/Utilities/GetStockShelf?StockID=${stockID}&LocationID=${locationID}&ShelfID=${null}`)
				.then((locationResponse) => {
					if (locationResponse.status === 200) {
						console.log("locationResponse", locationResponse);
						const stockData = locationResponse.data.length > 0 ? locationResponse.data[0] : { qtyOnHand: 0 }; // Check if data is empty and set qtyOnHand to 0 if it is
						if (stockData.qtyOnHand < 0) {
							utils.popupFormSetErrorForm(formRef, {
								visible: true,
								message: "The current item quantity at this location is less than zero.",
								type: "Warning"
							});


						}
						currentRecords[rowIndex]["FromQuantity"] = stockData.qtyOnHand;
					} else {
						console.error('Default location not found');
						// Handle the case where there is no default location
					}
				});

		} else if (columnName === "TaxCodeID") {
			currentRecords[rowIndex]["TaxRate"] = selectedItem?.rate ?? 0;
			const copiedArr = [...taxCodeList];
			if (!utils.isInArray(taxCodeList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setTaxCodeList(copiedArr);
		} else if (columnName === "ProjectID") {
			currentRecords[rowIndex]["ProjectCode"] = selectedItem?.code;
		} else if (columnName === "ShelfID") {
			const copiedArr = [...shelfList];
			if (!utils.isInArray(shelfList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setShelfList(copiedArr);

		} else if (columnName === "ToLocationID") {
			currentRecords[rowIndex]["ToLocationCode"] = selectedItem?.code;
		} else if (columnName === "FromLocationID") {
			currentRecords[rowIndex]["FromLocationCode"] = selectedItem?.code;
			// Make the API call to get the quantity on hand

			//if have bin
			//const shelfID = currentRecords[rowIndex]["ShelfID"];
			//baseapi.httpget(`/api/Utilities/GetStockShelf?StockID=${stockID}&LocationID=${e.value}&ShelfID=${shelfID}`)

			baseapi.httpget(`/api/Utilities/GetStockShelf?StockID=${stockID}&LocationID=${locationID}&ShelfID=${null}`)
				.then((locationResponse) => {
					if (locationResponse.status === 200) {
				
						const stockData = locationResponse.data.length > 0 ? locationResponse.data[0] : { qtyOnHand: 0 }; // Check if data is empty and set qtyOnHand to 0 if it is
						if (stockData.qtyOnHand < 0) {
							utils.popupFormSetErrorForm(formRef, {
								visible: true,
								message: "The current item quantity at this location is less than zero.",
								type: "Warning"
							});


						}
						currentRecords[rowIndex]["FromQuantity"] = stockData.qtyOnHand;
					} else {
						console.error('Default location not found');
						// Handle the case where there is no default location
					}
				});

		} 

		e["key"] = key;
		e["data"] = currentRecords[rowIndex];
		onRowUpdated(e, currentRecords);
	};



	function valueOnChange(e, TakeDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {

			const dataField = TakeDataField !== null ? TakeDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);
			if (!utils.isNullOrEmpty(e.value)) {
				if (dataField === "ForexID" && formRead.current) {
					baseapi
						.httpget(`/api/Forex/Get`, { id: e.value })
						.then((response) => {
							const { data } = response;
							const copied = { ...tempForm.current };
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;

							tempForm.current = copied;
							setFormValue(tempForm.current);
						})
						.catch((error) => {
							console.log(error);
						});
				}
			}
		} catch (err) {
			console.log(err);
		}
	}


	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		formID.current = null;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	function handleSubmit(e) {
		if (!utils.isNullOrEmpty(e.validData)) {
			const validData = e.validData;

			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]
			};

			loading("Saving Stock Transfer...");

			baseapi.httppost(
				utils.extendUrlVar("/api/StockTransfer/save", {
					id: formID.current || "",
					del: false,
				}),
				submittedForm
			)
				.then((response) => {
					const { data } = response;
					utils.popupFormSuccessfulSubmit(formRef, data);
					if (data.status) {
						//setFormValue({})
						if (typeof props.onSubmitSuccess === 'function') {
							props.onSubmitSuccess(); // Call the passed function on success
						}
					}
					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: data.message,
						type: data.status ? "Success" : "Danger",
						action: data.action
					});
				});
		}
	}

	function calculateSubTotal(rowData) {

		const quantity = parseFloat(rowData.ToQuantity);
		const unitCost = parseFloat(rowData.UnitCost);
		if (isNaN(quantity) || isNaN(unitCost)) {
			return 0;
		}
		const subTotal = utils.multiply(rowData.ToQuantity, rowData.UnitCost);
		return subTotal;
	}

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	return <div><PopupForm
		ref={formRef}
		childrenGridRef={childrenGridRef}
		onClosing={clearForm}
		fullScreen={false}
		width={"95%"}
		height={"90%"}
		disableButtons={props.lockedForm === true}
		headerClassName={"inventory-module-form-title-bg popup-form-title"}
		creditChecking={false}
		title={"Stock Transfer"}
		onShown={onInitialized}
		apiURL={"/api/StockTransfer"}
		onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized() }}
		requiredColumns={{
			Children: ["StockID", "FromLocationID", "ToLocationID","ProjectID"]
		}}
		filterRules={{
			Children: [
				{ name: "StockID", rules: [{ name: "Required" }] },
				{ name: "Description", rules: [{ name: "Required" }] },
				{ name: "Description2", rules: [{ name: "Required" }] },
				{ name: "Quantity", rules: [{ name: "Min", value: 1 }] },
			]
		}}
		copyFields={{
			Parent: formValue,
			Children: utils.childrenGridGetSource(childrenGridRef)
		}}
		onPasteForm={(e) => {
			tempForm.current = e.Parent;
			setFormValue(e.Parent);
			onRowUpdated({ key: null }, e.Children);
		}}
		copyStorage={"StockTransferCopiedData"}
		formData={formValue}
		isEditing={isEditing}
		onValidating={setStartValidation}
		startValidation={startValidation}
		onSaving={handleSubmit}
		cancelDocumentCustomization={
			<span className='popup-form-header-cancel'>
				<CheckBox
					value={formValue["Cancelled"]}
					elementAttr={{ dataField: "Cancelled" }}
					onValueChanged={(e) => {
						valueOnChange(e);
					}}
					alignment='left'
					width={"12px"}
					height={"12px"}
					iconSize={"12px"}
					readOnly={props.lockedForm === true}
					className='popup-header-cancel-input'
				/>

				<span className='popup-header-cancel-text'>Cancelled</span>
			</span>
		}
	>
		<div className='popup-form-main-content'>
			<div className='inventory-module-parent-section custom-form-grid-section-60-40'>
				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label next-doc-label'>Stock Transfer No: </div>
						<div className='popup-group-form-input'>
							<div className='next-doc-display'>Next Doc: {formValue["NextDocNo"]}</div>
							<AsyncTextBox
								value={formValue["DocumentNo"]}
								elementAttr={{ dataField: "DocumentNo" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								placeholder={"<NEW>"}
								disabled={formID.current !== "new" || props.lockedForm === true}
								asyncURL={"api/StockTransfer/CheckDuplicateDocNo"}
								asyncMessage={"Stock Transfer No is already taken!"}
							/>

						</div>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Reference No: </div>
						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["RefNo"]}
								elementAttr={{ dataField: "RefNo" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								readOnly={props.lockedForm === true}

							>


							</TextBox>
						</div>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Date: </div>
						<div className='popup-group-form-input'>
							<DateBox
								value={formValue["DocumentDate"]}
								elementAttr={{ dataField: "DocumentDate" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								displayFormat='dd/MM/yyyy'
								dateSerializationFormat='yyyy-MM-dd'
								useMaskBehavior={true}
								readOnly={props.lockedForm === true}
							>
								<Validator>
									{startValidation !== 0 && <ValidatorRequired message={"Date is required!"} />}
								</Validator>
							</DateBox>
						</div>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label '>Issue By: </div>
						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["IssueBy"]}
								elementAttr={{ dataField: "IssueBy" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								readOnly={true}
							/>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Forex: </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								ref={forexLookupRef}
								className={"ar-listing-datagrid"}
								dataSource={forexList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["ForexID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "ForexID");
								}}
								required={true}
								requiredMessage={"Forex is required!"}

								dataSourceURL={"api/Utilities/GetForexs"}
								onDataSourceChanged={setForexList}
								preread={true}>
								<Column dataField='code'></Column>
								<Column dataField='name'></Column>
							</CustomizedLookup>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Local Rate: </div>

						<div className='popup-group-form-input'>
							<NumberBox
								value={formValue["LocalRate"]}
								elementAttr={{ dataField: "LocalRate" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								format={utils.LocalRateFormat}
								readOnly={formValue["ForexID"] === defaultValue["ForexID"]}>
								<Validator>{startValidation !== 0 && <ValidatorRequired message={"Local Rate is required!"} />}</Validator>
							</NumberBox>
						</div>
					</div>
				</div>

				<div>
					<br />
					<div className='popup-group-form-item group-form-item-align-top'>
						<div className='popup-group-form-label next-doc-label'>Remarks: </div>
						<div className='popup-group-form-input'>
							<TextArea
								value={formValue["Remarks"]}
								elementAttr={{ dataField: "Remarks" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								height={116}
								readOnly={props.lockedForm === true}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='children-datagrid-container inventory-module-children-datagrid' style={{ padding: "0 0" }}>
				<ChildrenDatagrid
					ref={childrenGridRef}
					name={"Children"}
					defaultColumnValues={onInitNew}
					lookupFields={["StockID", "ProjectID", "ChartAccountID", "TaxCodeID"]}
					keyExpr='ID'
					showBorders={true}
					autoAddRow={false}
					loadPanel={false}
					allowColumnReordering={true}
					allowColumnResizing={true}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onRowRemoved={onRowUpdated}
					disabled={props.lockedForm === true}
					gridLookupOpen={setGridLookupOpen}
					storageName={"StockTransferdChildrenGrid"}
					onParentValueChanged={valueOnChange}
					defaultDescription={formValue["Description"]}
					onEditorPreparing={onEditorPreparing}



				>
					<Column dataField='Line' dataType='number' defaultColumnIndex={0} />

					<Column
						visible={true}
						dataField='StockID'
						dataType='string'
						caption='Stock Code'
						cellRender={(e) => {
							const data = e.data;
							return data.StockCode;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								dataSourceURL={"/api/Utilities/GetStock"}
								opened={gridLookupOpen}
								className={"workshop-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "StockID")}

							>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>

					<Column dataField='Description' />

					<Column dataField='Description2' />

					<Column visible={true} dataField='MoreDescription' dataType='string' isHtmlEditor={true} />

					<Column
								visible={true}
								dataField='ProjectID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.ProjectCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")}
										dataSourceURL={"/api/Utilities/GetProjects"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}
								editorOptions={{ readOnly: true }}></Column>
					<Column
						visible={true}
						dataField='FromLocationID'
						caption="From Location"
						dataType='string'
						cellRender={(e) => {
							const data = e.data;
							return data.FromLocationCode;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "FromLocationID")}
								dataSourceURL={"api/Utilities/GetLocations"}
								onDataSourceChanged={setFromLocationList}

								sourceList={fromLocationList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>
					<Column visible={true} dataField='FromQuantity' dataType='number' caption="Current Qty" width="100px" editorOptions={{ readOnly: true }} allowEditing={false} format={{ type: "fixedPoint", precision: 2 }} />
					<Column
						visible={true}
						dataField='ToLocationID'
						caption="To Location"
						dataType='string'
						cellRender={(e) => {
							const data = e.data;
							return data.ToLocationCode;
						}}
						editCellComponent={(component) => {

							// Check if component.row and component.row.data are defined
							if (component.data && component.data.data.hasOwnProperty('FromLocationID') && component.data.data.FromLocationID !== undefined && component.data.data.FromLocationID !== null) {
								const fromLocationID = component.data.data.FromLocationID;
								const dataSourceURL = `/api/Utilities/GetExcludeLocation?LocationID=${fromLocationID}`;
								return (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"inventory-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ToLocationID")}
										dataSourceURL={"api/Utilities/GetLocations"}
										onDataSourceChanged={setToLocationList}
										sourceList={toLocationList}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								);
							} else {
								console.error('rowData is undefined or StockID does not exist on rowData');
								// Handle the case where rowData is not as expected
							}

						}}



					></Column>




					<Column visible={true} dataField='ToQuantity' dataType='number' caption="Transfer Qty" width="100px" format={{ type: "fixedPoint", precision: 2 }} />
					<Column visible={true} dataField='UOM' editorOptions={{ readOnly: true }} width="70px" />
					<Column visible={false} dataField='ItemDiscount' dataType='number' />
					<Column visible={true} dataField='UnitCost' dataType='number' format={{ type: "fixedPoint", precision: 2 }} />

					{/* //self add on */}
					<Column
						dataField='Sub Total'
						dataType='number'
						format={{ type: "fixedPoint", precision: 2 }}
						allowEditing={false}
						calculateCellValue={calculateSubTotal}
					/>



					<Column visible={false} dataField='NettPrice' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />


					<Column visible={false} dataField='TaxRate' dataType='number' editorOptions={{ readOnly: true }} />

					<Column visible={false} dataField='TaxInclusive' dataType='boolean' />

					<Column visible={false} dataField='ForexTaxable' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

					<Column visible={false} dataField='ForexTaxAmount' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

					<Column visible={false} dataField='ForexTaxablePlusTax' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

					<Column visible={false} dataField='ItemDiscountAmt' dataType='number' />

					<Column
						visible={false}
						dataField='TaxCodeID'
						width={150}
						cellRender={(e) => {
							const data = e.data;
							var result = taxCodeList.find((c) => c.id === data.TaxCodeID)?.code;
							result = data.TaxCodeID === null ? null : result;
							return result === undefined ? data.TaxCode : result;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
								dataSourceURL={"api/Utilities/GetGSTRate"}
								onDataSourceChanged={setTaxCodeList}
								sourceList={taxCodeList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>

					<Column visible={false} dataField='PartNo' />

					<Column visible={false} dataField='BrandCode' />

					<Column visible={false} dataField='LinePrint' />

					<Column visible={false} dataField='PrintPrice' dataType='boolean' />

					<Column visible={false} dataField='Confirmation' dataType='boolean' />

					<Column visible={false} dataField='Show' dataType='boolean' />

					<Column visible={false} dataField='SalesType' />

					<Column visible={false} dataField='PriceCheckID' />

					<Column visible={false} dataField='SalesCode' />

					<Column visible={false} dataField='OrderType' />

					<Column visible={false} dataField='BOM' dataType='boolean' />

					<Column visible={false} dataField='ProductionNo' />

					<Column visible={false} dataField='Details' dataType='boolean' />



					<Column visible={false} dataField='ModelCode' />


					<Column visible={false} dataField='SerialNo' />

					<Column visible={false} dataField='Project' />

					<Column visible={false} dataField='GroupNo' />

					<Column visible={false} dataField='GroupUp' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='GroupAmt' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='BaseRate' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='PriceDisplay' dataType='boolean' />

					<Column visible={false} dataField='ForexTaxAmountOrignal' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='ForexTaxAdjust' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='ForexAmount' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxable' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxAmount' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxAmountOrignal' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxAdjust' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxablePlusTax' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='NettAmount' caption='Nett Amount' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalAmount' dataType='number' format='#,##0.00' />

					<Summary recalculateWhileEditing={true}>
						<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='NettLocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='NettPrice' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='UniCost' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='NettAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ItemDiscountAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='GroupUp' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='GroupAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='BaseRate' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />


						<TotalItem column='SubTotal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
					</Summary>
				</ChildrenDatagrid>
			</div>

			<div className='inventory-module-total-section custom-form-grid-section-70-30'>


				<div></div>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Total: </div>

					<div className='popup-group-form-input'>
						<NumberBox
							value={formValue["MainLocalAmount"]}
							elementAttr={{ dataField: "MainLocalAmount" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"auto"}
							readOnly={true}
							format='#,##0.00'>
							{/* <Validator 
							>
								<ValidatorRequired message={"Outstanding is required!"} />
							</Validator> */}
						</NumberBox>
					</div>
				</div>
			</div>
		</div>
	</PopupForm>


	</div>
}
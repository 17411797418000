import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { TextBox } from "devextreme-react";
import CustomizedLookup from "../../../../components/lookup/Lookup";
import { Column } from "devextreme-react/data-grid";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import utils from "../../../../utils/common";

export default forwardRef(function OtherAccountForm(props, ref) {
	const emptyForm = {
		Parent: {
			SpecialAccountType: null,
			TaxCodeID: null
		}
	};
	const tempForm = useRef(emptyForm);
	const [isEditing, setIsEditing] = useState(null);
	const [formValue, setFormValue] = useState(emptyForm);
	const [showSpecialAccount, setShowSpecialAccount] = useState(false);
	const [isRoot, setIsRoot] = useState(false);
	const [startValidation, setStartValidation] = useState(0);

	const withSpecialAccount = ["IV", "OA", "CA", "CL", "OL", "OT"];

	useEffect(() => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged(formValue);
		}
	}, [formValue]);

	useEffect(() => {
		if (props.accountType) {
			const type = props.accountType;
			const found = withSpecialAccount.find((c) => c === type);

			if (found !== undefined) {
				setShowSpecialAccount(true);
			} else {
				setShowSpecialAccount(false);
			}
		}
	}, [props.accountType]);

	useEffect(() => {
		if (props.clearForm) {
			tempForm.current = emptyForm;
			setFormValue(tempForm.current);
		}
	}, [props.clearForm]);

	useEffect(() => {
		if (props.isEditing !== undefined) {
			setIsEditing(props.isEditing);
		}
	}, [props.isEditing]);

	useEffect(() => {
		if (props.node !== undefined && props.node !== null) {
			const node = props.node;

			if (node["level"] === 0) {
				setIsRoot(true);
			} else {
				setIsRoot(false);
			}
		}
	}, [props.node]);

	useEffect(() => {
		if (props.startValidation !== undefined) {
			setStartValidation(props.startValidation);
		}
	}, [props.startValidation]);

	useEffect(() => {
		if (props.validationReady !== undefined && startValidation !== 0) {
			props.validationReady(true);
		}
	}, [startValidation]);

	useImperativeHandle(ref, () => ({
		setValue(value) {
			tempForm.current = value;
			setFormValue(tempForm.current);
		}
	}));

	function valueOnChange(e, receivedDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };

			if (e.value !== null && e.value !== undefined) {
				if (dataField === "Name") {
					copied["Parent"][dataField] = utils.toUpperCase(e.value);
					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if (dataField === "Code") {
					copied["Parent"][dataField] = utils.toUpperCase(e.value);
					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else {
					copied["Parent"][dataField] = e.value;
					tempForm.current = copied;
					setFormValue(tempForm.current);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	if (isEditing && isRoot) {
		return (
			<div className='popup-form-item-container1'>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Description: </div>

					<div className='popup-group-form-input'>
						<TextBox
							value={formValue["Parent"]["Name"]}
							elementAttr={{ dataField: "Name" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"auto"}>
							<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
						</TextBox>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div className={`popup-form-item-container${showSpecialAccount ? "2" : "1"}`}>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Code: </div>

					<div className='popup-group-form-input'>
						<TextBox
							value={formValue["Parent"]["Code"]}
							elementAttr={{ dataField: "Code" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							disabled={false}>
							<Validator>{startValidation !== 0 && <ValidatorRequired message={"Code is required!"} />}</Validator>
						</TextBox>
					</div>
				</div>

				{showSpecialAccount && (
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Special Account: </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								// ref={customerLookupRef}
								dataSource={props.dropDownList.SpecialAccountTypeList}
								className={"ar-listing-datagrid"}
								displayExpr={"name"}
								valueExpr={"code"}
								value={formValue["Parent"]["SpecialAccountType"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "SpecialAccountType");
								}}
								startValidation={startValidation}
								required={false}
								requiredMessage={"Special Account is required!"}>
								<Column dataField='code'></Column>
								<Column dataField='name'></Column>
							</CustomizedLookup>
						</div>
					</div>
				)}
			</div>

			<div className='popup-form-item-container1'>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Description: </div>

					<div className='popup-group-form-input'>
						<TextBox
							value={formValue["Parent"]["Name"]}
							elementAttr={{ dataField: "Name" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"auto"}>
							<Validator>{startValidation !== 0 && <ValidatorRequired message={"Code is required!"} />}</Validator>
						</TextBox>
					</div>
				</div>
			</div>

			<div className={`popup-form-item-container${showSpecialAccount ? "2" : "1"}`}>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Tax: </div>

					<div className='popup-group-form-input'>
						<CustomizedLookup
							// ref={customerLookupRef}
							className={"ar-listing-datagrid"}
							displayExpr={"code"}
							valueExpr={"id"}
							value={formValue["Parent"]["TaxCodeID"]}
							onSelectionChanged={(e) => {
								valueOnChange(e, "TaxCodeID");
							}}
							dataSourceURL={"api/Utilities/GetGSTRate"}
							onDataSourceChanged={(e) => props.taxCodeListChanged(e)}
							startValidation={startValidation}
							required={false}
							requiredMessage={"Tax Code is required!"}
							displayText={formValue["Parent"]["TaxCode"]}>
							<Column dataField='code'></Column>
							<Column dataField='name' caption={"Description"}></Column>
						</CustomizedLookup>
					</div>
				</div>

				{showSpecialAccount && (
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Forex: </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								// ref={customerLookupRef}
								className={"ar-listing-datagrid"}
								displayExpr={"name"}
								valueExpr={"id"}
								value={formValue["Parent"]["ForexID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "ForexID");
								}}
								startValidation={startValidation}
								required={true}
								requiredMessage={"Forex Code is required!"}
								dataSourceURL={"api/Utilities/GetForexs"}
								displayText={formValue["Parent"]["ForexCode"]}>
								<Column dataField='code'></Column>
								<Column dataField='name'></Column>
							</CustomizedLookup>
						</div>
					</div>
				)}
			</div>
		</div>
	);
});

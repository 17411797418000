
import { useEffect, useRef, useState, forwardRef, useCallback, useMemo } from "react";

import StockReceivedForm from './StockReportListing';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea, SelectBox, DropDownBox, Popover } from "devextreme-react";
import { Button } from "devextreme-react/button";
import CustomizedLookup from "../../../components/lookup/Lookup";

import { stockOptions, stockGroupOptions, itemTypeOptions, brandOptions, categoryOptions, workshopModel, locationOptions } from "../../../utils/lookupstore";
import { DataGrid, Button as Pager, Toolbar, Column, Selection } from "devextreme-react/data-grid";
import StockAdjustmentForm from "../adjustment/form";
import StockIssueForm from "../issue/form";
import StockReceiveForm from "../received/form";
import StockTakeForm from "../take/form";
import StockTransferForm from "../transfer/form";
import StockWriteOff from "../write-off/form";
import StockTransferFrom from "../transfer/form";
import StockWriteOffForm from "../write-off/form";

const StockReportListing = forwardRef((props, ref) => {

    const fromStockLookupRef = useRef(null);
    const toStockLookupRef = useRef(null);
    const [fromStockList, setFromStockList] = useState([]);
    const [toStockList, setToStockList] = useState([]);


    const fromStockGroupLookupRef = useRef(null);
    const toStockGroupLookupRef = useRef(null);
    const [fromStockGroupList, setFromStockGroupList] = useState([]);
    const [toStockGroupList, setToStockGroupList] = useState([]);


    const fromLocationLookupRef = useRef(null);
    const toLocationLookupRef = useRef(null);
    const [fromLocationList, setFromLocationList] = useState([]);
    const [toLocationList, setToLocationList] = useState([]);



    const fromTypeLookupRef = useRef(null);
    const toTypeLookupRef = useRef(null);
    const [fromTypeList, setFromTypeList] = useState([]);
    const [toTypeList, setToTypeList] = useState([]);

    const fromBrandLookupRef = useRef(null);
    const toBrandLookupRef = useRef(null);
    const [fromBrandList, setFromBrandList] = useState([]);
    const [toBrandList, setToBrandList] = useState([]);

    const fromCategoryLookupRef = useRef(null);
    const toCategoryLookupRef = useRef(null);
    const [fromCategoryList, setFromCategoryList] = useState([]);
    const [toCategoryList, setToCategoryList] = useState([]);


    const fromModelLookupRef = useRef(null);
    const toModelLookupRef = useRef(null);
    const [fromModelList, setFromModelList] = useState([]);
    const [toModelList, setToModelList] = useState([]);

    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentPrefix, setCurrentEditPrefix] = useState(null);
    const [fromDate, setFromDate] = useState(
        new Date(new Date().setDate(1)).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })
    );
    const [toDate, setToDate] = useState(
        new Date(new Date()).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })
    );

    const [currentForm, setCurrentForm] = useState(null);
    const [selectedStock, setSelectedStock] = useState(0);
    const [openStockRange, setOpenStockRange] = useState();
    const [fromStockOpened, setFromStockOpened] = useState(false);
    const popupMessageRef = useRef(null);
    const [toStockOpened, setToStockOpened] = useState(false);
    const tempForm = useRef(null);
    const [selectedStockGroup, setSelectedStockGroup] = useState(0);
    const [openStockGroupRange, setOpenStockGroupRange] = useState();
    const [fromStockGroupOpened, setFromStockGroupOpened] = useState(false);
    const [toStockGroupOpened, setToStockGroupOpened] = useState(false);
    const userID = JSON.parse(localStorage.getItem("data"));

    const [selectedType, setSelectedType] = useState(0);
    const [openTypeRange, setOpenTypeRange] = useState();
    const [fromTypeOpened, setFromTypeOpened] = useState(false);
    const [toTypeOpened, setToTypeOpened] = useState(false);

    const [selectedBrand, setSelectedBrand] = useState(0);
    const [openBrandRange, setOpenBrandRange] = useState();
    const [fromBrandOpened, setFromBrandOpened] = useState(false);
    const [toBrandOpened, setToBrandOpened] = useState(false);


    const [selectedCategory, setSelectedCategory] = useState(0);
    const [openCategoryRange, setOpenCategoryRange] = useState();
    const [fromCategoryOpened, setFromCategoryOpened] = useState(false);
    const [toCategoryOpened, setToCategoryOpened] = useState(false);
    const currentToken = localStorage.getItem("Authorization").substring(7);
    const [selectedModel, setSelectedModel] = useState(0);
    const [openModelRange, setOpenModelRange] = useState();
    const [fromModelOpened, setFromModelOpened] = useState(false);
    const [toModelOpened, setToModelOpened] = useState(false);

    const [selectedLocation, setSelectedLocation] = useState(0);
    const [openLocationRange, setOpenLocationRange] = useState();
    const [fromLocationOpened, setFromLocationOpened] = useState(false);
    const [toLocationOpened, setToLocationOpened] = useState(false);
    const searchBoxRef = useRef(null);
    const gridRef = useRef(null);
    const dropdownRef = useRef(null);
    const currentFocusIndex = useRef(null);
    const [originalDropDownList, setOriginalDropDownList] = useState({});

    const pagingIndex = useRef({
		loading: false,
		page: 1
	});
    const returnSelectedValue = (type) => {
        const filtered = paramValue.filter((x) => x.ParameterName === type);
        return filtered.length > 0 ? filtered[0].ParameterValue : null;
    };

    const [dropDownList, setDropDownList] = useState({

        stockList: [],
        statementList: [],
        categoryList: [],
        stockGroupList: [],
        typeList: [],
        modelList: [],
        brandList: [],
        locationList: []
    });
    const [paramValue, setParamValue] = useState([
        {
            ParameterName: "FromDate",
            ParameterValue: utils.dateboxFormat(fromDate)
        },
        {
            ParameterName: "ToDate",
            ParameterValue: utils.dateboxFormat(toDate)
        },
        {
            ParameterName: "IsZeroQuantity",
            ParameterValue: "N"
        },
        {
            ParameterName: "IsZeroQuantityCost",
            ParameterValue: "N"
        }

    ]);
    useEffect(() => {
		if (pagingIndex.current["loading"] === true) {
			pagingIndex.current["page"] += 1;
			pagingIndex.current["loading"] = false;
		}
	}, [dropDownList]);
    useEffect(() => {
        Promise.allSettled([
            stockOptions.store.load(),
            stockGroupOptions.store.load(),
            itemTypeOptions.store.load(),
            brandOptions.store.load(),
            categoryOptions.store.load(),
            workshopModel.store.load(),
            locationOptions.store.load(),
        ]).then((lists) => {
            setDropDownList({
                stockList: lists[0].value.data,
                stockGroupList: lists[1].value.data,
                typeList: lists[2].value.data,
                brandList: lists[3].value.data,
                categoryList: lists[4].value.data,
                modelList: lists[5].value.data,
                locationList: lists[6].value.data,
            });
            setOriginalDropDownList({
                stockList: lists[0].value.data,
                stockGroupList: lists[1].value.data,
                typeList: lists[2].value.data,
                brandList: lists[3].value.data,
                categoryList: lists[4].value.data,
                modelList: lists[5].value.data,
                locationList: lists[6].value.data,
            });


        });
    }, []);
    function refreshLookupData(type) {
        if (type === "Stock") {
            setDropDownList((prevState) => ({
                ...prevState,
                stockList: [...originalDropDownList.stockList]
            }));
        } else if (type === "Type") {
            setDropDownList((prevState) => ({
                ...prevState,
                typeList: [...originalDropDownList.typeList]
            }));
        } else if (type === "StockGroup") {
            setDropDownList((prevState) => ({
                ...prevState,
                stockGroupList: [...originalDropDownList.stockGroupList]
            }));
        } else if (type === "Category") {
            setDropDownList((prevState) => ({
                ...prevState,
                categoryList: [...originalDropDownList.categoryList]
            }));
        } else if (type === "Model") {
            setDropDownList((prevState) => ({
                ...prevState,
                modelList: [...originalDropDownList.modelList]
            }));
        } else if (type === "Location") {
            setDropDownList((prevState) => ({
                ...prevState,
                locationList: [...originalDropDownList.locationList]
            }));
        }
    }


    const removeParamValue = (key) => {
        setParamValue((prevParamValue) => {

            const updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
            return updatedParamValue;
        });
    };


    const popFunction = (e, type) => {
        if (e.cancel === false) {
            if (type === "Stock") {
                setOpenStockRange(false);
            }
            else if (type === "Type") {
                setOpenTypeRange(false);
            }
            else if (type === "StockGroup") {
                setOpenStockGroupRange(false);
            } else if (type === "Brand") {
                setOpenBrandRange(false);

            }
            else if (type === "Category") {
                setOpenCategoryRange(false);
            }
            else if (type === "Model") {
                setOpenModelRange(false);
            }
            else if (type === "Location") {
                setOpenLocationRange(false);
            }
        }
        else {
            if (type === "Stock") {
                setOpenStockRange(true);
            } else if (type === "Type") {
                setOpenTypeRange(true);
            }
            else if (type === "StockGroup") {
                setOpenStockGroupRange(true);
            } else if (type === "Brand") {
                setOpenBrandRange(true);
            }
            else if (type === "Category") {
                setOpenCategoryRange(true);
            }
            else if (type === "Model") {
                setOpenModelRange(true);
            }
            else if (type === "Location") {
                setOpenLocationRange(true);
            }
        }
    };

    function handleSubmit() {
        loading("Fetching Filter Result...");
        var parameters = `?userID=${userID?.userID}&reportName=${props.reportName}`;

        // Clone and format the paramValue
        tempForm.current = [...paramValue];

        // Prepare the copied values, ensuring correct format for the backend
        var copied = tempForm.current.map(param => {
            return {
                ParameterName: param.ParameterName,
                ParameterValue: Array.isArray(param.ParameterValue)
                    ? param.ParameterValue.join(",")
                    : param.ParameterValue, // Handle string case
                ParameterID: param.ParameterID
            };
        });
        baseapi.httppost(`${utils.ReportURL}/api/Report/StockListing${parameters}`, copied)
            .then((response) => {
                const { data } = response;
                console.log(data, "data");

                if (data !== null) {
                    props.ReturnResult({
                        MainData: data.map(item => item.MainData),
                        Details: data.map(item => item.Details),
                        FromDate: fromDate,
                        ToDate: toDate
                    });
                    closeLoading();
                }

                if (data.status) {
                    utils.displayPopupMessage(popupMessageRef, {
                        visible: true,
                        message: data.message,
                        type: "Danger"
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                closeLoading();
            });
    }

    const handlePrintBtn = (e) => {
        if (props.reportName) {
            baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportID?paramReportName=${props.reportName}&accessToken=${currentToken}`).then((response) => {
                const { data } = response;
                data.map((items) => {
                    confirmReportParameters(items.ID, items.ViewerAccessURL);
                });
            });
        }
    };

    const confirmReportParameters = async (reportID, ViewerAccessURL) => {
        var documentViewerLink = ViewerAccessURL;

        try {
            for (const param of paramValue) {
                switch (param.ParameterName) {
                    case "FromDate":
                    case "ToDate":
                        documentViewerLink += `&${param.ParameterName}=${param.ParameterValue}`;
                        break;
                    case "IsZeroQuantity":
                        documentViewerLink += `&${'ShowZeroQuantity'}=${param.ParameterValue}`;
                        break;
                    case "IsZeroQuantityCost":
                        documentViewerLink += `&${'ShowZeroQuantityCost'}=${param.ParameterValue}`;
                        break;

                }
            }

            // Add other parameters as per the SQL query conditions
            const addParameter = (paramName, value) => {
                if (value !== undefined && value !== null) {
                    documentViewerLink += `&${paramName}=${value}`;
                }
            };

            const addCondition = (indexName, listName, fromName, toName) => {
                let fromValue = null;
                let toValue = null;

                // Iterate through the parameters to find the relevant values
                paramValue.forEach(param => {
                    if (param.ParameterName === listName) {
                        addParameter(listName, param.ParameterID);
                        addParameter(indexName, 2);
                    } else if (param.ParameterName === fromName) {
                        fromValue = param.ParameterID;
                    } else if (param.ParameterName === toName) {
                        toValue = param.ParameterID;
                    }
                });

                // Add the parameters based on the conditions
                if (fromValue !== null && toValue !== null) {
                    addParameter(fromName, fromValue);
                    addParameter(toName, toValue);
                    addParameter(indexName, 3);
                }
            };

            // Add parameters for each condition
            addCondition("ParameterStockIndex", "StockList", "FromStock", "ToStock");
            addCondition("ParameterTypeIndex", "TypeList", "FromType", "ToType");
            addCondition("ParameterStockGroupIndex", "StockGroupList", "FromStockGroup", "ToStockGroup");
            addCondition("ParameterBrandIndex", "BrandList", "FromBrand", "ToBrand");
            addCondition("ParameterCategoryIndex", "CategoryList", "FromCategory", "ToCategory");
            addCondition("ParameterModelIndex", "ModelList", "FromModel", "ToModel");
            addCondition("ParameterLocationIndex", "LocationList", "FromLocation", "ToLocation");

            // Open the document viewer link in a new tab
            window.open(documentViewerLink, "_blank");
        } catch (error) {
            console.error("Error fetching report parameters:", error);
        }
    };
    function valueOnChange(e, type = null) {
        try {

            if (e.value !== undefined) {
                const dataField = e.element.getAttribute("dataField");
                if (dataField === "FromDate") {
                    setFromDate(e.value);
                } else if (dataField === "ToDate") {
                    setToDate(e.value);
                } else if (dataField === "FilterNumber") {
                    if (e.value === 1) {
                        switch (type) {
                            case "Stock":
                                setSelectedStock(e.value);
                                setOpenStockRange(true);
                                break;
                            case "Type":
                                setSelectedType(e.value);
                                setOpenTypeRange(true);
                                break;
                            case "StockGroup":
                                setSelectedStockGroup(e.value);
                                setOpenStockGroupRange(true);
                                break;
                            case "Brand":
                                setSelectedBrand(e.value);
                                setOpenBrandRange(true);
                                break;
                            case "Category":
                                setSelectedCategory(e.value);
                                setOpenCategoryRange(true);
                                break;

                            case "Model":
                                setSelectedModel(e.value);
                                setOpenModelRange(true);
                                break;
                            case "Location":
                                setSelectedLocation(e.value);
                                setOpenLocationRange(true);
                                break;
                            default:
                                // setSelectedID(0);
                                break;
                        }
                    }
                    if (e.value === 2) {
                        switch (type) {
                            case "Stock":
                                setSelectedStock(e.value);
                                break;
                            case "Type":
                                setSelectedType(e.value);
                                break;
                            case "StockGroup":
                                setSelectedStockGroup(e.value);
                                break;
                            case "Brand":
                                setSelectedBrand(e.value);
                                break;
                            case "Category":
                                setSelectedCategory(e.value);
                                break;
                            case "Model":
                                setSelectedModel(e.value);
                                break;

                            case "Location":
                                setSelectedLocation(e.value);
                                break;
                            default:

                                break;
                        }
                    }
                }

                if (dataField !== "FilterNumber") updateParamValue(dataField, e.value);
            }
        } catch (err) {
            console.log(err);
        }
    }
    const returnSelectedKeys = (type) => {
        const paramObject = paramValue.reduce((acc, curr) => {
            acc[curr.ParameterName] = curr.ParameterValue;
            return acc;
        }, {});


        let selectedKeys = [];

        if (type === "Stock" && paramObject["StockList"]) {
            selectedKeys = ensureArray(paramObject["StockList"]);
        } else if (type === "Type" && paramObject["TypeList"]) {
            selectedKeys = ensureArray(paramObject["TypeList"]);
        } else if (type === "StockGroup" && paramObject["StockGroupList"]) {
            selectedKeys = ensureArray(paramObject["StockGroupList"]);
        } else if (type === "Brand" && paramObject["BrandList"]) {
            selectedKeys = ensureArray(paramObject["BrandList"]);
        } else if (type === "Category" && paramObject["CategoryList"]) {
            selectedKeys = ensureArray(paramObject["CategoryList"]);
        } else if (type === "Model" && paramObject["ModelList"]) {
            selectedKeys = ensureArray(paramObject["ModelList"]);
        } else if (type === "Location" && paramObject["LocationList"]) {
            selectedKeys = ensureArray(paramObject["LocationList"]);
        }

        return selectedKeys;
    };

    const ensureArray = (value) => {
        if (Array.isArray(value)) {
            return value;
        } else {
            return value.split(",");
        }
    };
    const arrayToString = (arr) => {

        if (Array.isArray(arr)) {
            return arr.join(" ");
        } else {
            return arr;
        }
    };
    const updateParamValue = (key, value, idList) => {

 
        setParamValue((prevParamValue) => {
            const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array

            // Find the index of the item with the matching key
            const index = updatedParamValue.findIndex((item) => item.ParameterName === key);

            if (key === "FromDate" || key === "ToDate" || key === "IsZeroQuantity" || key === "IsZeroQuantityCost") {
                // Directly update the ParameterValue for FromDate and ToDate
                if (index !== -1) {
                    updatedParamValue[index] = { ...updatedParamValue[index], ParameterValue: value };
                } else {
                    updatedParamValue.push({ ParameterName: key, ParameterValue: value });
                }
            } else if (key.includes("List")) {
                // Handle List parameters with idList
                const parameterIdList = idList.map(item => item.id).join(',');
                if (index !== -1) {
                    updatedParamValue[index] = { ParameterName: key, ParameterValue: value, ParameterID: parameterIdList };
                } else {
                    updatedParamValue.push({ ParameterName: key, ParameterValue: value, ParameterID: parameterIdList });
                }
            } else {
                const parameterIdList = idList;
                // General case for other parameters
                if (index !== -1) {
                    updatedParamValue[index] = { ParameterName: key, ParameterValue: value, ParameterID: parameterIdList };
                } else {
                    updatedParamValue.push({ ParameterName: key, ParameterValue: value, ParameterID: parameterIdList });
                }
            }

            return updatedParamValue;
        });
    };

    function DataGridMultiRender(type) {
        let selectedKeys = returnSelectedKeys(type);

        return (
            <div className='customized-lookup-container'>
                <div className='stock-customized-lookup-search-container'>
                    <div>
                        <TextBox
                            ref={searchBoxRef}
                            placeholder='Search...'
                            width={250}
                            valueChangeEvent='keyup'
                            showClearButton={true}
                            onValueChanged={(e) => {
                                gridRef.current.instance.searchByText(e.value);
                                updateDropDownList(e.value, type, pagingIndex.current["page"], false);
                            }}
                            onKeyDown={(e) => {
                                // console.log("key down", e.event)
                                if (e.event.key === "ArrowDown") {
                                    gridRef.current.instance.focus();
                                }
                            }}
                        />
                    </div>

                    <div className='customized-lookup-btn-section popup-form-item-container2'>
                        <Button
                            text='Clear'
                            onClick={(e) => {
                                if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();

                            }}
                        />
                        <Button
                            text='Clear Filter'
                            stylingMode='text'
                            type='default'
                            className='custom-button'
                            onClick={() => {
                                if (type === "Stock") {
                                    removeParamValue("StockList");
                                    removeParamValue("FromStock");
                                    removeParamValue("ToStock");
                                    setSelectedStock(0);

                                } else if (type === "Type") {
                                    removeParamValue("TypeList");
                                    removeParamValue("FromType");
                                    removeParamValue("ToType");
                                    setSelectedType(0);
                                } else if (type === "StockGroup") {
                                    removeParamValue("StockGroupList");
                                    removeParamValue("FromStockGroup");
                                    removeParamValue("ToStockGroup");
                                    setSelectedStockGroup(0);
                                }
                                else if (type === "Brand") {
                                    removeParamValue("BrandList");
                                    removeParamValue("FromBrand");
                                    removeParamValue("ToBrand");
                                    setSelectedBrand(0);
                                }
                                else if (type === "Category") {
                                    removeParamValue("CategoryList");
                                    removeParamValue("FromCategory");
                                    removeParamValue("ToCategory");
                                    setSelectedCategory(0);
                                }
                                else if (type === "Model") {
                                    removeParamValue("ModelList");
                                    removeParamValue("FromModel");
                                    removeParamValue("ToModel");
                                    setSelectedModel(0);
                                } else if (type === "Location") {
                                    removeParamValue("LocationList");
                                    removeParamValue("FromLocation");
                                    removeParamValue("ToLocation");
                                    setSelectedLocation(0);
                                }
                                refreshLookupData(type);
                            }}

                            elementAttr={{ dataField: "FilterNumber" }}
                        />

                    </div>
                </div>
                <DataGrid
                    ref={gridRef}
                    height={345}
                    className={"lookup-datagrid"}
                    showBorders={true}
                    dataSource={returnDropDownList(type)}
                    scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
                    columnChooser={{ enabled: false }}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    hoverStateEnabled={true}
                    paging={{ enabled: false }}
                    keyExpr='code'
                    focusedRowEnabled={true}
                    focusedRowKey={selectedKeys}
                    selectedRowKeys={selectedKeys}
                    onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
                    onKeyDown={(e) => {
                        // if(e.event.key === "Enter"){
                        //     setDropdownOpened(false);
                        // }
                        if (e.event.key === "ArrowUp") {
                            // If focus is one the first row then brings focus back to the search box
                            if (currentFocusIndex.current === 0) {
                                if (type === "Stock") {
                                    removeParamValue("StockList");
                                } else if (type === "Type") {
                                    removeParamValue("TypeList");
                                } else if (type === "StockGroup") {
                                    removeParamValue("StockGroupList");
                                } else if (type === "Brand") {
                                    removeParamValue("BrandList");
                                } else if (type === "Category") {
                                    removeParamValue("CategoryList");
                                } else if (type === "Model") {
                                    removeParamValue("ModelList");
                                } else if (type === "Location") {
                                    removeParamValue("LocationList");
                                }
                                refreshLookupData(type);
                            }
                        }
                    }}
                    onFocusedRowChanging={(e) => {
                        if (e.event === null) {
                            e.cancel = true;
                        } else if (e.event.key === undefined) {
                            e.cancel = true;
                        }
                    }}
                    onContentReady={(element) => {
						const scrollable = element.component.getScrollable();
						if (scrollable !== undefined) {
							scrollable.on("scroll", function (e) {
								if (e.reachedBottom) {
									if (pagingIndex.current["loading"] === false) {
										pagingIndex.current["loading"] = true;
										updateDropDownList("", type, pagingIndex.current["page"], true);
									}
								}
							});
						}
					}}>
                    <Column
                        dataField='code'
                        elementAttr={{ dataField: type === "Stock" ? "StockCode" : type === "Type" ? "TypeCode" : type === "StockGroup" ? "StockGroupCode" : "BrandCode" }}
                        caption={type === "Stock" ? "Stock Code" : type === "Type" ? "Type Code" : type === "StockGroup" ? "Stock Group Code" : "Brand Code"}
                        visible={true}
                        alignment='left'
                        allowGrouping={false}
                    />

                    <Column
                        dataField='name'
                        elementAttr={{ dataField: type === "Stock" ? "StockName" : type === "Type" ? "TypeName" : type === "StockGroup" ? "StockGroupName" : "BrandName" }}
                        caption={type === "Stock" ? "Stock Name" : type === "Type" ? "Type Name" : type === "StockGroup" ? "Stock Group Name" : "Brand Name"}
                        visible={true}
                        alignment='left'
                        allowGrouping={false}
                    />

                    <Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
                    <Toolbar visible={false} />
                </DataGrid>


            </div>
        );
    }


    const dataGridOnSelectionChanged = (e, type) => {
        if (type === "Stock") {
            updateParamValue("StockList", e.selectedRowKeys, e.selectedRowsData);
        } else if (type === "Type") {
            updateParamValue("TypeList", e.selectedRowKeys, e.selectedRowsData);
        } else if (type === "StockGroup") {
            updateParamValue("StockGroupList", e.selectedRowKeys, e.selectedRowsData);
        } else if (type === "Brand") {
            updateParamValue("BrandList", e.selectedRowKeys, e.selectedRowsData);
        } else if (type === "Category") {
            updateParamValue("CategoryList", e.selectedRowKeys, e.selectedRowsData);
        } else if (type === "Model") {
            updateParamValue("ModelList", e.selectedRowKeys, e.selectedRowsData);
        } else if (type === "Location") {
            updateParamValue("LocationList", e.selectedRowKeys, e.selectedRowsData);
        }
    };

    const returnDropDownList = (type) => {
        if (type === "Stock") {
            return dropDownList.stockList;
        } else if (type === "Type") {
            return dropDownList.typeList;
        } else if (type === "StockGroup") {
            return dropDownList.stockGroupList;
        } else if (type === "Brand") {
            return dropDownList.brandList;
        } else if (type === "Category") {
            return dropDownList.categoryList;
        } else if (type === "Model") {
            return dropDownList.modelList;
        } else if (type === "Location") {
            return dropDownList.locationList;
        }
    };

    const filterTypes = [
        {
            ID: 0,
            Type: "No Filter"
        },
        {
            ID: 1,
            Type: "Filter By Range"
        },
        {
            ID: 2,
            Type: "Filter By Multi-Select"
        }
    ];

    const returnDropDownListURL = (type) => {

        if (type === "Stock") {
            return "api/Utilities/GetStock";
        } else if (type === "Type") {
            return "api/Utilities/GetItemTypes";
        } else if (type === "StockGroup") {
            return "api/Utilities/GetStockGroups";
        } else if (type === "Brand") {
            return "api/Utilities/GetBrands";
        } else if (type === "Category") {
            return "api/Utilities/GetItemCategories";
        } else if (type === "Model") {
            return "api/Utilities/GetItemModels";
        } else if (type === "Location") {
            return "api/Utilities/GetLocations";
        }

    };

    const updateDropDownList = (paramValue, type, page, paging) => {
		const typeToArrayNameMap = {
			Stock: "stockList",
			Model: "modelList",
			Type: "typeList",
			StockGroup: "stockGroupList",
			Brand: "brandList",
			Category: "categoryList",
			Location: "locationList"
		};
		var value = "";

		if (!paging) {
			page = 0;
		}

		if (paramValue !== undefined) {
			value = paramValue;
		}

		const arrayName = typeToArrayNameMap[type];
		const query = value.length === 0 ? `${paramValue}` : `${paramValue} ${arrayToString(returnSelectedKeys(type))}`;

		baseapi
			.httpget(returnDropDownListURL(type), { q: query, singleReturn: true, page: page })
			.then((response) => {
				const data = response.data;
				// console.log(data);
				setDropDownList((prevState) => {
					const existingArray = prevState[arrayName] || [];
					const newData = data.filter((newItem) => !existingArray.some((existingItem) => existingItem.id === newItem.id));
					return {
						...prevState,
						[arrayName]: [...existingArray, ...newData]
					};
				});
			})
			.catch(() => {
				throw "Network error";
			});
	};
    const [isZeroQuantityChecked, setIsZeroQuantityChecked] = useState(false);
    const [isZeroQuantityCostChecked, setIsZeroQuantityCostChecked] = useState(false);

    const handleZeroQuantityChange = (e) => {
        const newValue = e.value ? 'Y' : 'N';
        updateParamValue("IsZeroQuantity", newValue);

        if (e.value) {
            // Uncheck IsZeroQuantityCost and set it to 'N'
            updateParamValue("IsZeroQuantityCost", 'N');
            setIsZeroQuantityCostChecked(false); // Uncheck the other checkbox
        }
        setIsZeroQuantityChecked(e.value); // Update the current checkbox state
    };

    const handleZeroQuantityCostChange = (e) => {
        const newValue = e.value ? 'Y' : 'N';
        updateParamValue("IsZeroQuantityCost", newValue);

        if (e.value) {
            // Uncheck IsZeroQuantity and set it to 'N'
            updateParamValue("IsZeroQuantity", 'N');
            setIsZeroQuantityChecked(false); // Uncheck the other checkbox
        }
        setIsZeroQuantityCostChecked(e.value); // Update the current checkbox state
    };
    return <>

        <div>

            <div className=' popup-form-main-content'>
                <div className='custom-form-grid-section-33-33-33 inventory-module-parent-without-margin-bottom-section' style={{ marginLeft: 0, marginTop: 0 }}>
                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'>From Date: </div>
                            <div className='popup-group-form-input'>
                                <DateBox
                                    type='date'
                                    value={fromDate}
                                    elementAttr={{ dataField: "FromDate" }}
                                    useMaskBehavior={true}
                                    max={toDate}
                                    displayFormat='dd/MM/yyyy'
                                    dateSerializationFormat='yyyy-MM-dd'
                                    onValueChanged={(e) => {
                                        valueOnChange(e, "FromDate");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'>To Date: </div>
                            <div className='popup-group-form-input'>
                                <DateBox
                                    type='date'
                                    value={toDate}
                                    min={fromDate}
                                    elementAttr={{ dataField: "ToDate" }}
                                    useMaskBehavior={true}
                                    displayFormat='dd/MM/yyyy'
                                    dateSerializationFormat='yyyy-MM-dd'
                                    onValueChanged={(e) => {
                                        valueOnChange(e, "ToDate");
                                    }}

                                />
                            </div>
                        </div>

                    </div>

                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'> Stock Code : </div>
                            <div className='popup-group-form-input'>
                                {selectedStock === 0 && (
                                    <SelectBox

                                        dataSource={filterTypes}
                                        value={selectedStock}
                                        displayExpr={"Type"}
                                        valueExpr={"ID"}
                                        elementAttr={{ dataField: "FilterNumber" }}
                                        onValueChanged={(e) => {
                                            valueOnChange(e, "Stock");
                                        }}
                                    />
                                )}
                                {selectedStock === 1 && (
                                    <div onClick={() => setOpenStockRange(true)}>
                                        <TextBox
                                            value={returnSelectedValue("FromStock") || returnSelectedValue("ToStock") ? `${returnSelectedValue("FromStock")} to ${returnSelectedValue("ToStock")}` : "No Range Selected Yet"}
                                            id='Stock'
                                            style={{ marginRight: "10px" }}
                                        >
                                            <Popover
                                                target='#Stock'
                                                showEvent={"click"}
                                                // hideEvent="mouseleave"
                                                position='bottom'
                                                visible={openStockRange}
                                                onHiding={(e) => popFunction(e, "Stock")}
                                                dragEnabled={false}
                                                hideOnOutsideClick={true}

                                                height={125}>
                                                <div>
                                                    <div className='popup-form-item-container2'>
                                                        <div>
                                                            <label>From</label>
                                                            <CustomizedLookup
                                                                ref={fromStockLookupRef}
                                                                dataSource={fromStockList.filter((item, index) => {
                                                                 let toIndex = toStockList.indexOf(toStockList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToStock')?.ParameterValue));   
                                                                 if (toIndex == -1) {
                                                                    return true;
                                                                 }
                                                                   return index <=  toIndex;
                                                                }
                                                                )}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                value={returnSelectedValue("FromStock")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("FromStock", e.value.code, e.value.id);
                                                                }}
                                                                sortByColumn={false}
                                                                dataSourceURL={"api/Utilities/GetStock"}
                                                                onDataSourceChanged={setFromStockList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Stock Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Stock Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                        <div>
                                                            <label>To</label>
                                                            <CustomizedLookup
                                                                ref={toStockLookupRef}
                                                                dataSource={toStockList.filter((item, index) => index >= fromStockList.indexOf(fromStockList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromStock')?.ParameterValue)))}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                value={returnSelectedValue("ToStock")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("ToStock", e.value.code, e.value.id);
                                                                }}
                                                                sortByColumn={false}
                                                                dataSourceURL={"api/Utilities/GetStock"}
                                                                onDataSourceChanged={setToStockList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Stock Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Stock Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            style={{ position: "absolute", top: "65%", left: "75%" }}
                                                            text='Clear Filter'
                                                            stylingMode='text'
                                                            type='default'
                                                            onClick={() => {
                                                                removeParamValue("StockList");
                                                                removeParamValue("FromStock");
                                                                removeParamValue("ToStock");
                                                                setSelectedStock(0);
                                                            }}
                                                            elementAttr={{ dataField: "FilterNumber" }}
                                                        />
                                                    </div>
                                                </div>
                                            </Popover>
                                        </TextBox>
                                    </div>
                                )}
                                {selectedStock === 2 && (
                                    <div>
                                        <TextBox
                                            value={returnSelectedKeys("Stock").length !== 0 ? `${returnSelectedKeys("Stock").length} Selected  (${returnSelectedKeys("Stock")})` : `${returnSelectedKeys("Stock").length} Selected`}
                                            style={{ marginTop: "-35px" }}
                                            height={0}
                                            showClearButton={false}
                                        >
                                            <DropDownBox
                                                //onClosed={(e) => closeDropDownBox(e, "Stock")}
                                                ref={dropdownRef}
                                                // opened={openStockSpecific}
                                                valueExpr='code'
                                                deferRendering={false}
                                                displayExpr='code'
                                                showClearButton={false}
                                                contentRender={(e) => DataGridMultiRender("Stock")}
                                                defaultOpened={true}


                                                dropDownOptions={{ width: "auto" }}
                                            />


                                        </TextBox>
                                    </div>


                                )}
                            </div>
                        </div>
                    </div>
                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'> Stock Group : </div>
                            <div className='popup-group-form-input'>
                                {selectedStockGroup === 0 && (
                                    <SelectBox
                                        dataSource={filterTypes}
                                        value={selectedStockGroup}
                                        displayExpr={"Type"}
                                        valueExpr={"ID"}
                                        elementAttr={{ dataField: "FilterNumber" }}
                                        onValueChanged={(e) => {
                                            valueOnChange(e, "StockGroup");
                                        }}
                                    />
                                )}
                                {selectedStockGroup === 1 && (
                                    <div onClick={() => setOpenStockGroupRange(true)}>
                                        <TextBox
                                            value={returnSelectedValue("FromStockGroup") || returnSelectedValue("ToStockGroup") ? `${returnSelectedValue("FromStockGroup")} to ${returnSelectedValue("ToStockGroup")}` : "No Range Selected Yet"}
                                            // useMaskBehavior={true} // Enable mask behavior
                                            // mask="cccccc" // Define the input mask
                                            id='StockGroup'
                                            style={{ marginRight: "10px" }}
                                        >
                                            <Popover
                                                target='#StockGroup'
                                                showEvent={"click"}
                                                // hideEvent="mouseleave"
                                                position='bottom'
                                                visible={openStockGroupRange}
                                                onHiding={(e) => popFunction(e, "StockGroup")}
                                                dragEnabled={false}
                                                hideOnOutsideClick={true}
                                                width={500}
                                                height={125}>
                                                <div>
                                                    <div className='popup-form-item-container2'>
                                                        <div>
                                                            <label>From</label>
                                                            <CustomizedLookup
                                                                ref={fromStockGroupLookupRef}
                                                                dataSource={fromStockGroupList.filter((item, index) => {
                                                                    let toIndex = toStockGroupList.indexOf(toStockGroupList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToStockGroup')?.ParameterValue));   
                                                                    if (toIndex == -1) {
                                                                       return true;
                                                                    }
                                                                      return index <=  toIndex;
                                                                   }
                                                                   )}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                sortByColumn={false}
                                                                value={returnSelectedValue("FromStockGroup")}
                                                                OnCustomSelectionChanged={(e) => {

                                                                    updateParamValue("FromStockGroup", e.value.code, e.value.id);
                                                                }}
                                                                dataSourceURL={"api/Utilities/GetStockGroups"}
                                                                onDataSourceChanged={setFromStockGroupList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Stock Group Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Stock Group Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>

                                                        </div>
                                                        <div>
                                                            <label>To</label>
                                                            <CustomizedLookup
                                                                ref={toStockGroupLookupRef}
                                                                dataSource={toStockGroupList.filter((item, index) => index >= fromStockGroupList.indexOf(fromStockGroupList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromStockGroup')?.ParameterValue)))}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                sortByColumn={false}
                                                                value={returnSelectedValue("ToStockGroup")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("ToStockGroup", e.value.code, e.value.id);
                                                                }}
                                                                dataSourceURL={"api/Utilities/GetStockGroups"}
                                                                onDataSourceChanged={setToStockGroupList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Stock Group Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Stock Group Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            style={{ position: "absolute", top: "65%", left: "75%" }}
                                                            text='Clear Filter'
                                                            stylingMode='text'
                                                            type='default'
                                                            onClick={() => {
                                                                removeParamValue("StockGroupList");
                                                                removeParamValue("FromStockGroup");
                                                                removeParamValue("ToStockGroup");
                                                                setSelectedStockGroup(0);
                                                            }}
                                                            elementAttr={{ dataField: "FilterNumber" }}
                                                        />
                                                    </div>
                                                </div>
                                            </Popover>
                                        </TextBox>
                                    </div>
                                )}
                                {selectedStockGroup === 2 && (
                                    <div>
                                        <TextBox
                                            value={returnSelectedKeys("StockGroup").length !== 0 ? `${returnSelectedKeys("StockGroup").length} Selected  (${returnSelectedKeys("StockGroup")})` : `${returnSelectedKeys("StockGroup").length} Selected`}
                                            style={{ marginTop: "-35px" }}
                                            height={0}
                                            showClearButton={false}
                                        >
                                            <DropDownBox
                                                ref={dropdownRef}
                                                // opened={openStockGroupSpecific}
                                                // onClosed={(e) => closeDropDownBox(e, "StockGroup")}
                                                valueExpr='id'
                                                deferRendering={false}
                                                displayExpr='code'
                                                showClearButton={false}
                                                contentRender={() => DataGridMultiRender("StockGroup")}
                                                defaultOpened={true}

                                                dropDownOptions={{ width: "auto" }}
                                            />
                                        </TextBox>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'> Item Location : </div>
                            <div className='popup-group-form-input'>
                                {selectedLocation === 0 && (
                                    <SelectBox
                                        dataSource={filterTypes}
                                        value={selectedLocation}
                                        displayExpr={"Type"}
                                        valueExpr={"ID"}
                                        elementAttr={{ dataField: "FilterNumber" }}
                                        onValueChanged={(e) => {
                                            valueOnChange(e, "Location");
                                        }}
                                    />
                                )}
                                {selectedLocation === 1 && (
                                    <div onClick={() => setOpenLocationRange(true)}>
                                        <TextBox
                                            value={returnSelectedValue("FromLocation") || returnSelectedValue("ToLocation") ? `${returnSelectedValue("FromLocation")} to ${returnSelectedValue("ToLocation")}` : "No Range Selected Yet"}
                                            id='Location'
                                            style={{ marginRight: "10px" }}
                                        >
                                            <Popover
                                                target='#Location'
                                                showEvent={"click"}
                                                // hideEvent="mouseleave"
                                                position='bottom'
                                                visible={openLocationRange}
                                                onHiding={(e) => popFunction(e, "Location")}
                                                dragEnabled={false}
                                                hideOnOutsideClick={true}

                                                height={125}>
                                                <div>
                                                    <div className='popup-form-item-container2'>
                                                        <div>
                                                            <label>From</label>
                                                            <CustomizedLookup
                                                                ref={fromLocationLookupRef}
                                                                dataSource={fromLocationList.filter((item, index) => {
                                                                    let toIndex = toLocationList.indexOf(toLocationList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToLocation')?.ParameterValue));   
                                                                    if (toIndex == -1) {
                                                                       return true;
                                                                    }
                                                                      return index <=  toIndex;
                                                                   }
                                                                   )}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                value={returnSelectedValue("FromLocation")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("FromLocation", e.value.code, e.value.id);
                                                                }}
                                                                sortByColumn={false}
                                                                dataSourceURL={"api/Utilities/GetLocations"}
                                                                onDataSourceChanged={setFromLocationList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Location Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Location Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                        <div>
                                                            <label>To</label>
                                                            <CustomizedLookup
                                                                ref={toLocationLookupRef}
                                                                dataSource={toLocationList.filter((item, index) => index >= fromLocationList.indexOf(fromLocationList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromLocation')?.ParameterValue)))}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                sortByColumn={false}
                                                                value={returnSelectedValue("ToLocation")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("ToLocation", e.value.code, e.value.id);
                                                                }}
                                                                dataSourceURL={"api/Utilities/GetLocations"}
                                                                onDataSourceChanged={setToLocationList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Location Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Location Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            style={{ position: "absolute", top: "65%", left: "75%" }}
                                                            text='Clear Filter'
                                                            stylingMode='text'
                                                            type='default'
                                                            onClick={() => {
                                                                removeParamValue("LocationList");
                                                                removeParamValue("FromLocation");
                                                                removeParamValue("ToLocation");
                                                                setSelectedLocation(0);
                                                            }}
                                                            elementAttr={{ dataField: "FilterNumber" }}
                                                        />
                                                    </div>
                                                </div>
                                            </Popover>
                                        </TextBox>
                                    </div>
                                )}
                                {selectedLocation === 2 && (
                                    <div>
                                        <TextBox
                                            value={returnSelectedKeys("Location").length !== 0 ? `${returnSelectedKeys("Location").length} Selected  (${returnSelectedKeys("Location")})` : `${returnSelectedKeys("Location").length} Selected`}
                                            style={{ marginTop: "-35px" }}
                                            height={0}
                                            showClearButton={false}
                                        >
                                            <DropDownBox
                                                ref={dropdownRef}
                                                valueExpr='id'
                                                deferRendering={false}
                                                displayExpr='code'
                                                showClearButton={false}
                                                contentRender={(e) => DataGridMultiRender("Location")}
                                                defaultOpened={true}
                                                dropDownOptions={{ width: "auto" }}
                                            />
                                        </TextBox>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'> Item Type : </div>
                            <div className='popup-group-form-input'>
                                {selectedType === 0 && (
                                    <SelectBox

                                        dataSource={filterTypes}
                                        value={selectedType}
                                        displayExpr={"Type"}
                                        valueExpr={"ID"}
                                        elementAttr={{ dataField: "FilterNumber" }}
                                        onValueChanged={(e) => {
                                            valueOnChange(e, "Type");
                                        }}
                                    />
                                )}
                                {selectedType === 1 && (
                                    <div onClick={() => setOpenTypeRange(true)}>
                                        <TextBox
                                            value={returnSelectedValue("FromType") || returnSelectedValue("ToType") ? `${returnSelectedValue("FromType")} to ${returnSelectedValue("ToType")}` : "No Range Selected Yet"}
                                            // useMaskBehavior={true} // Enable mask behavior
                                            // mask="cccccc" // Define the input mask
                                            id='Type'
                                            style={{ marginRight: "10px" }}
                                        >
                                            <Popover
                                                target='#Type'
                                                showEvent={"click"}
                                                // hideEvent="mouseleave"
                                                position='bottom'
                                                visible={openTypeRange}
                                                onHiding={(e) => popFunction(e, "Type")}
                                                dragEnabled={false}
                                                hideOnOutsideClick={true}

                                                height={125}>
                                                <div>
                                                    <div className='popup-form-item-container2'>
                                                        <div>
                                                            <label>From</label>
                                                            <CustomizedLookup
                                                                ref={fromTypeLookupRef}
                                                                dataSource={fromTypeList.filter((item, index) => {
                                                                    let toIndex = toTypeList.indexOf(toTypeList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToType')?.ParameterValue));   
                                                                    if (toIndex == -1) {
                                                                       return true;
                                                                    }
                                                                      return index <=  toIndex;
                                                                   }
                                                                   )}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                value={returnSelectedValue("FromType")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("FromType", e.value.code, e.value.id);
                                                                }}
                                                                sortByColumn={false}
                                                                dataSourceURL={"api/Utilities/GetItemTypes"}
                                                                onDataSourceChanged={setFromTypeList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Type Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Type Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                        <div>
                                                            <label>To</label>
                                                            <CustomizedLookup
                                                                ref={toTypeLookupRef}
                                                                dataSource={toTypeList.filter((item, index) => index >= fromTypeList.indexOf(fromTypeList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromType')?.ParameterValue)))}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                value={returnSelectedValue("ToType")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("ToType", e.value.code, e.value.id);
                                                                }}
                                                                sortByColumn={false}
                                                                dataSourceURL={"api/Utilities/GetItemTypes"}
                                                                onDataSourceChanged={setToTypeList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Type Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Type Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            style={{ position: "absolute", top: "65%", left: "75%" }}
                                                            text='Clear Filter'
                                                            stylingMode='text'
                                                            type='default'
                                                            onClick={() => {
                                                                removeParamValue("TypeList");
                                                                removeParamValue("FromType");
                                                                removeParamValue("ToType");
                                                                setSelectedType(0);
                                                            }}
                                                            elementAttr={{ dataField: "FilterNumber" }}
                                                        />
                                                    </div>
                                                </div>
                                            </Popover>
                                        </TextBox>
                                    </div>
                                )}
                                {selectedType === 2 && (
                                    <div>
                                        <TextBox
                                            value={returnSelectedKeys("Type").length !== 0 ? `${returnSelectedKeys("Type").length} Selected  (${returnSelectedKeys("Type")})` : `${returnSelectedKeys("Type").length} Selected`}
                                            style={{ marginTop: "-35px" }}
                                            height={0}
                                            showClearButton={false}
                                        >
                                            <DropDownBox
                                                ref={dropdownRef}
                                                // opened={openTypeSpecific}
                                                // onClosed={(e) => closeDropDownBox(e, "Type")}
                                                valueExpr='id'
                                                deferRendering={false}
                                                displayExpr='code'
                                                showClearButton={false}
                                                contentRender={() => DataGridMultiRender("Type")}
                                                defaultOpened={true}

                                                dropDownOptions={{ width: "auto" }}
                                            />
                                        </TextBox>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>


                    <div className='popup-form-item-container'>

                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'> Item Brand : </div>
                            <div className='popup-group-form-input'>
                                {selectedBrand === 0 && (
                                    <SelectBox

                                        dataSource={filterTypes}
                                        value={selectedBrand}
                                        displayExpr={"Type"}
                                        valueExpr={"ID"}
                                        elementAttr={{ dataField: "FilterNumber" }}
                                        onValueChanged={(e) => {
                                            valueOnChange(e, "Brand");
                                        }}
                                    />
                                )}
                                {selectedBrand === 1 && (
                                    <div onClick={() => setOpenBrandRange(true)}>
                                        <TextBox
                                            value={returnSelectedValue("FromBrand") || returnSelectedValue("ToBrand") ? `${returnSelectedValue("FromBrand")} to ${returnSelectedValue("ToBrand")}` : "No Range Selected Yet"}
                                            // useMaskBehavior={true} // Enable mask behavior
                                            // mask="cccccc" // Define the input mask
                                            id='Brand'
                                            style={{ marginRight: "10px" }}
                                        >
                                            <Popover
                                                target='#Brand'
                                                showEvent={"click"}
                                                // hideEvent="mouseleave"
                                                position='bottom'
                                                visible={openBrandRange}
                                                onHiding={(e) => popFunction(e, "Brand")}
                                                dragEnabled={false}
                                                hideOnOutsideClick={true}

                                                height={125}>
                                                <div>
                                                    <div className='popup-form-item-container2'>
                                                        <div>
                                                            <label>From</label>
                                                            <CustomizedLookup
                                                                ref={fromBrandLookupRef}
                                                                dataSource={fromBrandList.filter((item, index) => {
                                                                    let toIndex = toBrandList.indexOf(toBrandList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToBrand')?.ParameterValue));   
                                                                    if (toIndex == -1) {
                                                                       return true;
                                                                    }
                                                                      return index <=  toIndex;
                                                                   }
                                                                   )}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                value={returnSelectedValue("FromBrand")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("FromBrand", e.value.code, e.value.id);
                                                                }}
                                                                sortByColumn={false}
                                                                dataSourceURL={"api/Utilities/GetBrands"}
                                                                onDataSourceChanged={setFromBrandList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Brand Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Brand Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                        <div>
                                                            <label>To</label>
                                                            <CustomizedLookup
                                                                ref={toBrandLookupRef}
                                                                dataSource={toBrandList.filter((item, index) => index >= fromBrandList.indexOf(fromBrandList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromBrand')?.ParameterValue)))}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                value={returnSelectedValue("ToBrand")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("ToBrand", e.value.code, e.value.id);
                                                                }}
                                                                sortByColumn={false}
                                                                dataSourceURL={"api/Utilities/GetBrands"}
                                                                onDataSourceChanged={setToBrandList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Brand Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Brand Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            style={{ position: "absolute", top: "65%", left: "75%" }}
                                                            text='Clear Filter'
                                                            stylingMode='text'
                                                            type='default'
                                                            onClick={() => {
                                                                setSelectedBrand(0);
                                                                removeParamValue("BrandList");
                                                                removeParamValue("FromBrand");
                                                                removeParamValue("ToBrand");
                                                            }}
                                                            elementAttr={{ dataField: "FilterNumber" }}
                                                        />
                                                    </div>
                                                </div>
                                            </Popover>
                                        </TextBox>
                                    </div>
                                )}
                                {selectedBrand === 2 && (
                                    <div>
                                        <TextBox
                                            value={returnSelectedKeys("Brand").length !== 0 ? `${returnSelectedKeys("Brand").length} Selected  (${returnSelectedKeys("Brand")})` : `${returnSelectedKeys("Brand").length} Selected`}
                                            style={{ marginTop: "-35px" }}
                                            height={0}
                                            showClearButton={false}
                                        >
                                            <DropDownBox
                                                // onClosed={(e) => closeDropDownBox(e, "Brand")}
                                                ref={dropdownRef}
                                                valueExpr='id'
                                                deferRendering={false}
                                                displayExpr='code'
                                                showClearButton={false}
                                                contentRender={(e) => DataGridMultiRender("Brand")}
                                                defaultOpened={true}
                                                dropDownOptions={{ width: "auto" }}
                                            />
                                        </TextBox>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'> Item Category : </div>
                            <div className='popup-group-form-input'>
                                {selectedCategory === 0 && (
                                    <SelectBox

                                        dataSource={filterTypes}
                                        value={selectedCategory}
                                        displayExpr={"Type"}
                                        valueExpr={"ID"}
                                        elementAttr={{ dataField: "FilterNumber" }}
                                        onValueChanged={(e) => {
                                            valueOnChange(e, "Category");
                                        }}
                                    />
                                )}
                                {selectedCategory === 1 && (
                                    <div onClick={() => setOpenCategoryRange(true)}>
                                        <TextBox
                                            value={returnSelectedValue("FromCategory") || returnSelectedValue("ToCategory") ? `${returnSelectedValue("FromCategory")} to ${returnSelectedValue("ToCategory")}` : "No Range Selected Yet"}
                                            // useMaskBehavior={true} // Enable mask behavior
                                            // mask="cccccc" // Define the input mask
                                            id='Category'
                                            style={{ marginRight: "10px" }}
                                        >
                                            <Popover
                                                target='#Category'
                                                showEvent={"click"}
                                                // hideEvent="mouseleave"
                                                position='bottom'
                                                visible={openCategoryRange}
                                                onHiding={(e) => popFunction(e, "Category")}
                                                dragEnabled={false}
                                                hideOnOutsideClick={true}

                                                height={125}>
                                                <div>
                                                    <div className='popup-form-item-container2'>
                                                        <div>
                                                            <label>From</label>
                                                            <CustomizedLookup
                                                                ref={fromCategoryLookupRef}
                                                                dataSource={fromCategoryList.filter((item, index) => {
                                                                    let toIndex = toCategoryList.indexOf(toCategoryList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToCategory')?.ParameterValue));   
                                                                    if (toIndex == -1) {
                                                                       return true;
                                                                    }
                                                                      return index <=  toIndex;
                                                                   }
                                                                   )}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                value={returnSelectedValue("FromCategory")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("FromCategory", e.value.code, e.value.id);
                                                                }}
                                                                sortByColumn={false}
                                                                dataSourceURL={"api/Utilities/GetItemCategories"}
                                                                onDataSourceChanged={setFromCategoryList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Category Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Category Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                        <div>
                                                            <label>To</label>
                                                            <CustomizedLookup
                                                                ref={toCategoryLookupRef}
                                                                dataSource={toCategoryList.filter((item, index) => index >= fromCategoryList.indexOf(fromCategoryList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromCategory')?.ParameterValue)))}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                value={returnSelectedValue("ToCategory")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("ToCategory", e.value.code, e.value.id);
                                                                }}
                                                                sortByColumn={false}
                                                                dataSourceURL={"api/Utilities/GetItemCategories"}
                                                                onDataSourceChanged={setToCategoryList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Category Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Category Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            style={{ position: "absolute", top: "65%", left: "75%" }}
                                                            text='Clear Filter'
                                                            stylingMode='text'
                                                            type='default'
                                                            onClick={() => {
                                                                removeParamValue("CategoryList");
                                                                removeParamValue("FromCategory");
                                                                removeParamValue("ToCategory");
                                                                setSelectedCategory(0);
                                                            }}
                                                            elementAttr={{ dataField: "FilterNumber" }}
                                                        />
                                                    </div>
                                                </div>
                                            </Popover>
                                        </TextBox>
                                    </div>
                                )}
                                {selectedCategory === 2 && (
                                    <div>
                                        <TextBox
                                            value={returnSelectedKeys("Category").length !== 0 ? `${returnSelectedKeys("Category").length} Selected  (${returnSelectedKeys("Category")})` : `${returnSelectedKeys("Category").length} Selected`}
                                            style={{ marginTop: "-35px" }}
                                            height={0}
                                            showClearButton={false}
                                        >
                                            <DropDownBox
                                                // onClosed={(e) => closeDropDownBox(e, "Category")}
                                                ref={dropdownRef}
                                                // opened={openCustSpecific}
                                                valueExpr='id'
                                                deferRendering={false}
                                                displayExpr='code'
                                                showClearButton={false}
                                                contentRender={(e) => DataGridMultiRender("Category")}
                                                defaultOpened={true}
                                                dropDownOptions={{ width: "auto" }}
                                            />
                                        </TextBox>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'> Item Model : </div>
                            <div className='popup-group-form-input'>
                                {selectedModel === 0 && (
                                    <SelectBox
                                        dataSource={filterTypes}
                                        value={selectedModel}
                                        displayExpr={"Type"}
                                        valueExpr={"ID"}
                                        elementAttr={{ dataField: "FilterNumber" }}
                                        onValueChanged={(e) => {
                                            valueOnChange(e, "Model");
                                        }}
                                    />
                                )}
                                {selectedModel === 1 && (
                                    <div onClick={() => setOpenModelRange(true)}>
                                        <TextBox
                                            value={returnSelectedValue("FromModel") || returnSelectedValue("ToModel") ? `${returnSelectedValue("FromModel")} to ${returnSelectedValue("ToModel")}` : "No Range Selected Yet"}
                                            // useMaskBehavior={true} // Enable mask behavior
                                            // mask="cccccc" // Define the input mask
                                            id='Model'
                                            style={{ marginRight: "10px" }}
                                        >
                                            <Popover
                                                target='#Model'
                                                showEvent={"click"}
                                                // hideEvent="mouseleave"
                                                position='bottom'
                                                visible={openModelRange}
                                                onHiding={(e) => popFunction(e, "Model")}
                                                dragEnabled={false}
                                                hideOnOutsideClick={true}

                                                height={125}>
                                                <div>
                                                    <div className='popup-form-item-container2'>
                                                        <div>
                                                            <label>From</label>
                                                            <CustomizedLookup
                                                                ref={fromModelLookupRef}
                                                                dataSource={fromModelList.filter((item, index) => {
                                                                    let toIndex = toModelList.indexOf(toModelList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'ToModel')?.ParameterValue));   
                                                                    if (toIndex == -1) {
                                                                       return true;
                                                                    }
                                                                      return index <=  toIndex;
                                                                   }
                                                                   )}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                sortByColumn={false}
                                                                value={returnSelectedValue("FromModel")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("FromModel", e.value.code, e.value.id);
                                                                }}
                                                                dataSourceURL={"api/Utilities/GetItemModels"}
                                                                onDataSourceChanged={setFromModelList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Model Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Model Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                        <div>
                                                            <label>To</label>
                                                            <CustomizedLookup
                                                                ref={toModelLookupRef}
                                                                dataSource={toModelList.filter((item, index) => index >= fromModelList.indexOf(fromModelList.find(e=>e.code == paramValue.find(e=>e.ParameterName == 'FromModel')?.ParameterValue)))}
                                                                displayExpr={"code"}
                                                                valueExpr={"code"}
                                                                sortByColumn={false}
                                                                value={returnSelectedValue("ToModel")}
                                                                OnCustomSelectionChanged={(e) => {
                                                                    updateParamValue("ToModel", e.value.code, e.value.id);
                                                                }}
                                                                dataSourceURL={"api/Utilities/GetItemModels"}
                                                                onDataSourceChanged={setToModelList}>
                                                                <Column dataField='code' elementAttr={{ dataField: "code" }} visible={true} caption='Model Code' alignment='left' allowGrouping={false} />
                                                                <Column dataField='name' elementAttr={{ dataField: "name" }} visible={true} caption='Model Name' alignment='left' allowGrouping={false} />
                                                            </CustomizedLookup>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            style={{ position: "absolute", top: "65%", left: "75%" }}
                                                            text='Clear Filter'
                                                            stylingMode='text'
                                                            type='default'
                                                            onClick={() => {
                                                                removeParamValue("ModelList");
                                                                removeParamValue("FromModel");
                                                                removeParamValue("ToModel");
                                                                setSelectedModel(0);
                                                            }}
                                                            elementAttr={{ dataField: "FilterNumber" }}
                                                        />
                                                    </div>
                                                </div>
                                            </Popover>
                                        </TextBox>
                                    </div>
                                )}
                                {selectedModel === 2 && (
                                    <div>
                                        <TextBox
                                            value={returnSelectedKeys("Model").length !== 0 ? `${returnSelectedKeys("Model").length} Selected  (${returnSelectedKeys("Model")})` : `${returnSelectedKeys("Model").length} Selected`}
                                            style={{ marginTop: "-35px" }}
                                            height={0}
                                            showClearButton={false}
                                        >
                                            <DropDownBox
                                                ref={dropdownRef}
                                                valueExpr='code'
                                                deferRendering={false}
                                                displayExpr='code'
                                                showClearButton={false}
                                                contentRender={(e) => DataGridMultiRender("Model")}
                                                defaultOpened={true}
                                                dropDownOptions={{ width: "auto" }}
                                            />
                                        </TextBox>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='popup-form-item-container' style={{ marginLeft: '10px', marginTop: '15px', display: 'flex', width: '500px' }}>
                        <CheckBox
                            value={isZeroQuantityChecked}
                            text='Show Zero Balance Quantity'
                            onValueChanged={handleZeroQuantityChange}
                        />&nbsp;&nbsp;&nbsp;
                        <CheckBox
                            value={isZeroQuantityCostChecked}
                            text='Show Zero Balance Quantity & Cost'
                            onValueChanged={handleZeroQuantityCostChange}
                        />
                    </div>
                    <div className='popup-form-item-container' style={{ marginLeft: '10px', marginTop: '5px' }}> </div> <div></div>
                    <div style={{ marginTop: "20px" }}>

                        <Button text='Inquiry' type='success' width={"auto"} onClick={(e) => handleSubmit(e)} />
                        <Button text='Print Report' type='success' stylingMode={"outlined"} width={"auto"} style={{ marginLeft: "20px", backgroundColor: "white", color: "#46CF64" }} onClick={(e) => handlePrintBtn(e)} />
                    </div>
                </div>


            </div>


        </div>



    </>



})


export default StockReportListing;